import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import authRoute from './authRoute';
import layoutRoute from './layoutRoute';

const routes = [
  layoutRoute,
  authRoute,
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const AppRouter = () => {
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default AppRouter;
