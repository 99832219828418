import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import ChatConversationMenuHeader from './ChatConversationMenuHeader';
import ChatConversationList from './ChatConversationList';

const width = 400;

const ChatConversationMenu = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        width={width}
        boxSizing={'border-box'}
        height={'100%'}
        borderRight={`1px solid ${theme.palette.divider}`}
        display={'flex'}
        flexDirection={'column'}
        pb={3}
      >
        <ChatConversationMenuHeader />
        <ChatConversationList />
      </Box>
    </Box>
  );
};

export default ChatConversationMenu;
