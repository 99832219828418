import React, { useState } from 'react';
import { Box, Card, CardContent, useTheme } from '@mui/material';
import MiniSidebar from '../templates/components/sidebar/MiniSidebar';
import ChatConversationMenu from '../containers/chat/conversation-menu/ChatConversationMenu';
import ChatboxInformation from '../containers/chat/chat-information/ChatInformation';

const Chat = () => {
  const theme = useTheme();

  return (
    <Box display={'flex'} flexDirection={'row'}>
      <Box>
        <MiniSidebar></MiniSidebar>
      </Box>
      <Box height={'calc(100vh - 64px)'} bgcolor={theme.palette.primary.main} flexGrow={1}>
        <Card sx={{ borderRadius: 0, borderTopLeftRadius: 8, height: '100%' }}>
          <CardContent sx={{ p: 0, height: '100%' }}>
            <Box height={'100%'} display={'flex'} flexDirection={'row'}>
              <ChatConversationMenu />
              <ChatboxInformation />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Chat;
