import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, List } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AccountUsageLimitItem from './AccountUsageLimitItem';
import userService from '../../../services/userService';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';

const AccountUsageLimit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [usageLimit, setUsageLimit] = useState(null);

  useEffect(() => {
    loadUsageLimit();
  }, []);

  const loadUsageLimit = () => {
    setIsLoading(true);
    userService
      .getUsageLimit()
      .then((res) => {
        setUsageLimit(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card>
      <CardHeader
        title="Giới hạn sử dụng"
        subheader="Tài nguyên đã sử dụng"
        sx={{
          pb: 0,
          '& .MuiCardHeader-subheader': { fontSize: 13, fontWeight: 400, color: 'text.main' },
        }}
      />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <CircularProgress />
        </Box>
      ) : (<>
        {usageLimit && (
          <CardContent sx={{ pt: 1 }}>
            <List sx={{ pt: 0 }}>
              <Grid container spacing={2}>
                <Grid item xl={6} sm={12} md={6}>
                  <AccountUsageLimitItem
                    title="Số lượng bot"
                    icon={<SmartToyOutlinedIcon sx={{ fontSize: 18 }} />}
                    count={usageLimit.usage.bot.total}
                    maxCount={usageLimit.limit.maxBot}
                  />
                  <AccountUsageLimitItem
                    title="Số lượng tin nhắn"
                    icon={<ChatOutlinedIcon sx={{ fontSize: 18 }} />}
                    count={usageLimit.usage.message.total}
                    maxCount={usageLimit.limit.maxMessage}
                  />
                </Grid>
                <Grid item xl={6} sm={12} md={6}>
                  <AccountUsageLimitItem
                    title="Số lượng file"
                    icon={<ChatOutlinedIcon sx={{ fontSize: 18 }} />}
                    count={usageLimit.usage.file.total}
                    maxCount={usageLimit.limit.maxFile * usageLimit.limit.maxBot}
                  />
                  <AccountUsageLimitItem
                    title="Số lượng url"
                    icon={<ChatOutlinedIcon sx={{ fontSize: 18 }} />}
                    count={usageLimit.usage.url.total}
                    maxCount={usageLimit.limit.maxUrl * usageLimit.limit.maxBot}
                  />
                </Grid>
              </Grid>
            </List>
          </CardContent>
        )}
      </>)
      }
    </Card>
  );
};

export default AccountUsageLimit;
