const transformTimeAgo = (date) => {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval === 1 ? '1 năm trước' : `${interval} năm trước`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval === 1 ? '1 tháng trước' : `${interval} tháng trước`;
  }
  interval = Math.floor(seconds / 604800);
  if (interval >= 1) {
    return interval === 1 ? '1 tuần trước' : `${interval} tuần trước`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval === 1 ? '1 ngày trước' : `${interval} ngày trước`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval === 1 ? '1 giờ trước' : `${interval} giờ trước`;
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval === 1 ? '1 phút trước' : `${interval} phút trước`;
  }
  return seconds === 1 ? '1 giây trước' : `${seconds} giây trước`;
};

const formatDate = (date) => {
  const d = new Date(date);
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  return `${hours}:${minutes} - ${day}/${month}/${year}`;
};

export { transformTimeAgo, formatDate };
