import { Box, Button, Grid } from '@mui/material';
import BotSocialConnectListItem from './BotSocialConnectListItem';
import userService from '../../../services/userService';

const BotSocialConnectList = ({ page }) => {
  const handleCancel = (botId) => {
    userService
      .cancelFb()
      .then((res) => {
       console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
       
      });
  };
  return (
    <Box>
      <Grid container spacing={2}>
        {page.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
            <BotSocialConnectListItem item={item} />
          </Grid>
        ))}
      </Grid>
      <Button variant="outlined" color="secondary" sx={{ mr: 1 ,mt:2}} onClick={handleCancel}>
        Hủy kết nốt Facebook
      </Button>
    </Box>
  );
};

export default BotSocialConnectList;
