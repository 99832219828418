import { Navigate } from 'react-router-dom';
import AccountPackage from '../containers/account/package/AccountPackage';
import AccountProfile from '../containers/account/profile/AccountProfile';
import AccountStatistic from '../containers/account/statistic/AccountStatistic';
import AccountAffiliation from '../containers/account/affiliation/AccountAffiliation';

const { default: Account } = require('../pages/Account');

const accountRoute = {
  path: 'account',
  element: <Account />,
  children: [
    {
      path: '',
      element: <Navigate to="packages" />,
    },
    {
      path: 'packages',
      element: <AccountPackage />,
    },
    {
      path: 'profile',
      element: <AccountProfile />,
    },
    {
      path: 'statistics',
      element: <AccountStatistic />,
    },
    {
      path: 'affiliation',
      element: <AccountAffiliation />,
    },
  ],
};

export default accountRoute;
