import { Box, FormGroup, FormLabel, Typography, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';

const width = 400;

const ChatConversationDetail = ({ chatbox }) => {
  const theme = useTheme();

  return (
    <Box
      width={width}
      height={'100%'}
      boxSizing={'border-box'}
      borderLeft={`1px solid ${theme.palette.divider}`}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Box px={3} py={2.5}>
          <Typography variant="h6" color={'primary.main'}>
            Chi tiết
          </Typography>
        </Box>
        <Box px={3}>
          <Box display={'flex'} alignItems={'center'} mb={2} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'}>
              <Grid3x3Icon sx={{ mr: 1, fontSize: 18 }} />
              <Typography variant="subtitle1" fontSize={15} fontWeight={500}>
                Id
              </Typography>
            </Box>
            <Typography variant="body1">{chatbox?.id}</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} mb={2} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'}>
              <PersonIcon sx={{ mr: 1, fontSize: 18 }} />
              <Typography variant="subtitle1" fontSize={15} fontWeight={500}>
                Họ và tên
              </Typography>
            </Box>
            <Typography variant="body1">{chatbox?.userInfo?.name}</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} mb={2} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'}>
              <PhoneIcon sx={{ mr: 1, fontSize: 18 }} />
              <Typography variant="subtitle1" fontSize={15} fontWeight={500}>
                Số điện thoại
              </Typography>
            </Box>
            <Typography variant="body1">{chatbox?.userInfo?.phone}</Typography>
          </Box>
          <Box display={'flex'} alignItems={'start'} mb={2} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'}>
              <HomeIcon sx={{ mr: 1, fontSize: 18 }} />
              <Typography variant="subtitle1" fontSize={15} fontWeight={500}>
                Địa chỉ
              </Typography>
            </Box>
            <Typography variant="body1" maxWidth={200}>
              {chatbox?.userInfo?.address}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatConversationDetail;
