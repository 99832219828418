import { Avatar, Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { botAction } from '../../../reducers/slices/botSlice';

const ListBotItem = ({ bot }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (bot?.id) {
      navigate(`/bots/${bot.id}/setting`);
      dispatch(botAction.setCurrentBot(bot));
    }
  };

  return (
    <Box onClick={handleClick}>
      <Card
        variant="outlined"
        sx={{ '&:hover': { backgroundColor: theme.palette.action.hover }, cursor: 'pointer' }}
      >
        <CardContent sx={{ '&.MuiCardContent-root': { p: 2 } }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Avatar src={bot?.avatar} variant="rounded" sx={{ width: 54, height: 54 }} />
            <Typography sx={{ ml: 1.5 }} variant="body1" fontWeight={500} fontSize={14}>
              {bot?.name}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ListBotItem;
