import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import BotImageUpload from './BotImageUpload';
import { useModal } from '../../hooks/useModal';
import { useParams } from 'react-router-dom';

const BotImagesItem = ({ image, handleSelect }) => {
  const { id } = useParams();
  const [selected, setSelected] = useState(image.selected || false);
  const botImageUploadUpdateModal = useModal('bot-image-upload', BotImageUpload);

  const handleCheck = () => {
    setSelected((prev) => !prev);
    if (handleSelect) {
      handleSelect(image, !selected);
    }
  };

  const handleOpenUpdateImageModal = () => {
    botImageUploadUpdateModal.openModal({ botId: id, image: image });
  };

  return (
    <Card variant="outlined" sx={{ position: 'relative' }} onClick={handleOpenUpdateImageModal}>
      <Box
        sx={{ position: 'absolute', top: 4, right: 4 }}
        display={'flex'}
        flexDirection={'column'}
      >
        <Checkbox
          sx={{ bgcolor: 'white', '&:hover': { bgcolor: 'white' }, zIndex: 99999 }}
          onClick={(e) => e.stopPropagation()}
          checked={selected}
          onChange={handleCheck}
        />
      </Box>
      <CardActionArea>
        <CardMedia
          sx={{ objectFit: 'contain' }}
          component="img"
          height="140"
          image={image?.file?.url}
          alt={image?.file?.name}
        />
        <CardContent sx={{ py: 1, px: 2, mb: 0, mt: 1 }}>
          <Typography
            gutterBottom
            variant="h6"
            sx={{ fontSize: 16 }}
            component="div"
            className="max-line-1"
          >
            {image?.file?.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BotImagesItem;
