import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormGroup,
  FormLabel,
  Typography,
  useTheme,
} from '@mui/material';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { Formik, Form } from 'formik';
import Textarea from '../../../components/forms/Textarea';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import botService from '../../../services/botService';

const BotUrlTranning = () => {
  const theme = useTheme();
  const botSelector = useSelector((state) => state.bot);
  const [isLoading, setIsLoading] = useState(false);
  const [bot, setBot] = useState(botSelector.currentBot);

  useEffect(() => {
    if (botSelector.currentBot) {
      setBot(botSelector.currentBot);
    }
  }, [botSelector.currentBot]);

  const convertUrlsToString = (urls) => {
    if (!urls) {
      return '';
    }
    return urls.map((url) => url).join('\n');
  };

  const convertUrlsToArray = (urls) => {
    if (!urls) {
      return [];
    }
    return urls.split('\n');
  };

  const initialValues = {
    urls: convertUrlsToString(bot?.tranningUrls),
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    let body = {
      urls: convertUrlsToArray(values.urls),
    };

    botService
      .tranningUrls(bot.id, body)
      .then((res) => {
        setBot(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Card>
      <CardContent>
        <CardContentHeader title="Tranning bằng url" />
        <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
          {({ handleChange, handleBlur, values }) => (
            <Form>
              <Box sx={{ display: 'flex', flexDirection: 'column' }} mb={3}>
                <FormGroup>
                  <FormLabel>
                    Danh sách url
                    <Typography
                      variant="caption"
                      sx={{ ml: 1, fontSize: theme.typography.fontSize }}
                    >
                      (Mỗi url nằm trên 1 dòng)
                    </Typography>
                  </FormLabel>
                  <Textarea
                    disabled={isLoading}
                    rows={10}
                    placeholder="https://www.google.com"
                    name="urls"
                    value={values.urls}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {isLoading && (
                    <Typography
                      variant="caption"
                      sx={{ mt: 1, fontSize: theme.typography.fontSize }}
                    >
                      <CircularProgress
                        sx={{ mr: 1 }}
                        color="inherit"
                        size={12}
                        thickness={5}
                        variant="indeterminate"
                      />
                      Đang tiến hành quét, vui lòng chờ...
                    </Typography>
                  )}
                </FormGroup>
              </Box>
              <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                <Button type="submit" variant="contained" disabled={isLoading}>
                  {!isLoading ? (
                    'Bắt đầu tranning'
                  ) : (
                    <span>
                      Đang Tranning
                      <CircularProgress
                        sx={{ ml: 1 }}
                        color="inherit"
                        size={12}
                        thickness={5}
                        variant="indeterminate"
                      />
                    </span>
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default BotUrlTranning;
