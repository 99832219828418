export const systemPackageEnum = {
  FREE: 'free',
  BASIC: 'basic',
  STANDARD: 'standard',
  ADVANCED: 'advanced',
  CUSTOM: 'custom',
};

export const getSystemPackageName = (systemPackage) => {
  switch (systemPackage?.name) {
    case systemPackageEnum.BASIC:
      return 'Cơ bản';
    case systemPackageEnum.STANDARD:
      return 'Tiêu chuẩn';
    case systemPackageEnum.ADVANCED:
      return 'Nâng cao';
    case systemPackageEnum.CUSTOM:
      return 'Tùy chỉnh';
    default:
      return 'Dùng thử';
  }
};

export const getSystemPackageCaption = (systemPackage) => {
  switch (systemPackage?.name) {
    case systemPackageEnum.BASIC:
      return 'Phù hợp với các cửa hàng cá nhân, mới bắt đầu';
    case systemPackageEnum.STANDARD:
      return 'Đáp ứng phần lớn nhu cầu của các doanh nghiệp nhỏ';
    case systemPackageEnum.ADVANCED:
      return 'Phù hợp các doanh nghiệp có lượng lớn khách hàng';
    case systemPackageEnum.CUSTOM:
      return 'Linh hoạt phù hợp với nhu cầu của doanh nghiệp';
    default:
      return 'Gói trải nghiệm dùng thử';
  }
};

export const getSystemPackageColor = (systemPackage, theme) => {
  switch (systemPackage?.name) {
    case systemPackageEnum.BASIC:
      return theme.palette.success;
    case systemPackageEnum.STANDARD:
      return theme.palette.info;
    case systemPackageEnum.ADVANCED:
      return theme.palette.warning;
    case systemPackageEnum.CUSTOM:
      return theme.palette.primary;
    default:
      return theme.palette.grey;
  }
};

export const packageColorMap = {
  basic: 'success',
  standard: 'info',
  advanced: 'warning',
  custom: 'primary',
};

export const getSystemPackageDiscount = (systemPackage) => {
  if (systemPackage?.name === systemPackageEnum.CUSTOM) {
    return 0;
  }
  return systemPackage?.basicPrice - systemPackage?.price;
};
