import { styled, Tab } from '@mui/material';

const TabCustom = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0.75, 1.5),
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.common.white,
  '&:hover': {
    opacity: 1,
  },
  minWidth: '24px',
  minHeight: '12px',
  fontSize: theme.typography.fontSize,
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
  '&:hover': {
    backgroundColor: theme.palette.info.light,
  },
  '&.Mui-focused': {
    color: theme.palette.text.primary,
  },
}));

export default TabCustom;
