import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

const AccountUsageLimitItem = ({ title, icon, count, maxCount }) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon sx={{ minWidth: 24 }}>
        {/* <ChatOutlinedIcon sx={{ fontSize: 18 }} /> */}
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={title}
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {count}/{maxCount}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default AccountUsageLimitItem;
