import { Button, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import QrCode2Icon from '@mui/icons-material/QrCode2';


const getCodeColorByName = (name) => ({
  basic: '#2e7d32',
  standard: '#0288d1',
  advanced: '#ed6c02',
  custom: '#24417C',
}[name] || 'black');

const getName = (name) => ({
  basic: 'Cơ bản',
  standard: 'Tiêu chuẩn',
  advanced: 'Nâng cao',
  custom: 'Tùy chỉnh',
}[name] || '');

const getStatusLabel = (status) => ({
  waiting: 'Đang chờ',
  reject: 'Đã hủy',
  active: 'Thành công',
}[status] || '');


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const AccountPackageTableItem = ({ pack, onShowQrCode }) => {
  if (!pack) return null;

  const { name, duration, price } = pack?.info || {};
  const codeColor = getCodeColorByName(name);
  const statusLabel = getStatusLabel(pack?.status);

  return (
    <StyledTableRow>
      <StyledTableCell style={{ color: codeColor, fontWeight: 'bold' }}>
        {pack.code}
      </StyledTableCell>
      <StyledTableCell>{getName(name)}</StyledTableCell>
      <StyledTableCell>{duration}</StyledTableCell>
      <StyledTableCell>{price?.toLocaleString('vi-VN')}</StyledTableCell>
      <StyledTableCell>{statusLabel}</StyledTableCell>
      <StyledTableCell>
        <Button onClick={() => onShowQrCode(pack)}>
          <QrCode2Icon />
        </Button>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default AccountPackageTableItem;
