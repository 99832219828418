import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState } from 'react';
import packageService from '../../../services/packageService';
import AccountPackageTableItem from './AccountPackageTableItem';
import QrCode from '../../../components/qr/QrCode'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const AccountPackageTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedPackage, setSelectedPackage] = useState(null); 
  const [qrOpen, setQrOpen] = useState(false); 

  useEffect(() => {
    loadPackages();
  }, []);

  const loadPackages = () => {
    setIsLoading(true);
    packageService
      .getPackages()
      .then((res) => {
        const sortedPackages = res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        console.log(sortedPackages);
        setPackages(sortedPackages);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, packages.length - page * rowsPerPage);

  const handleShowQrCode = (pack) => {
    setSelectedPackage(pack);
    setQrOpen(true);
  };

  const handleCloseQrCode = () => {
    setQrOpen(false);
    setSelectedPackage(null);
  };

  return (
    <Paper>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer sx={{ height: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Mã giao dịch</StyledTableCell>
                <StyledTableCell align="left">Tên gói</StyledTableCell>
                <StyledTableCell align="left">Thời hạn (tháng)</StyledTableCell>
                <StyledTableCell align="left">Giá (VND)</StyledTableCell>
                <StyledTableCell align="left">Trạng thái</StyledTableCell>
                <StyledTableCell align="left">Thao tác</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? packages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : packages
              ).map((pk) => (
                <AccountPackageTableItem 
                  key={pk.code} 
                  pack={pk} 
                  onShowQrCode={handleShowQrCode} 
                />
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={packages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        // ActionsComponent={PaginationActions}
        labelRowsPerPage="Số dòng mỗi trang"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} trên ${count}`}
      />
      {selectedPackage && (
        <QrCode 
          amount={selectedPackage.info.price} 
          message={`Thanh toán gói: ${selectedPackage.code}`} 
          open={qrOpen}
          onClose={handleCloseQrCode}
        />
      )}
    </Paper>
  );
};

const PaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: 'flex' }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Trang đầu"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Trang trước"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Trang sau"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Trang cuối"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

export default AccountPackageTable;
