import { Box, ImageList, ImageListItem, InputLabel, MenuItem, Typography, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import { getSystemPackageCaption } from '../../../../helpers/packageHelper';
import shop from '../../../../assets/media/images/shop.png';

export const SystemPackageItemDetail = ({ systemPackage, onPackageSelect }) => {
    const isCustomPackage = systemPackage?.name === "custom";

    const duration = systemPackage ? systemPackage.duration : '1';
    const [customPackage, setCustomPackage] = useState({
        duration: duration ? duration : '1',
        maxBot: '1',
        maxFile: '1',
        maxUrl: '1',
        maxCharacter: 256,
        maxMessage: '500',
        name: 'custom'
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCustomPackage((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        onPackageSelect(customPackage);
    };
    const capitalizeFirstLetter = (string) => {
        if (!string) return 'Updating...';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    return (
        <div>
            <Box display={'flex'} flexDirection={'column'}>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    gap={'15px'}
                >
                    <Box>
                        <ImageList sx={{ width: 100, height: 100 }} cols={1} rowHeight={50}>
                            <ImageListItem >
                                <img src={shop} alt="shop" />
                            </ImageListItem>
                        </ImageList>
                    </Box>
                    <Box>
                        <Typography variant="body1" fontWeight={'bold'} fontSize={20}>
                            {systemPackage.name ? capitalizeFirstLetter(systemPackage.name) : 'No name'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            {getSystemPackageCaption(systemPackage)}
                        </Typography>
                    </Box>
                </Box>

                <Grid container spacing={2}>
                    {/* Cột bên trái */}
                    <Grid item xs={6}>
                        <InputLabel id="select-label-month"
                            sx={{ marginTop: 2 }}>
                            Số tháng
                        </InputLabel>
                        <Select
                            labelId="select-label-month"
                            id="select-month"
                            name="duration"
                            value={customPackage.duration}
                            label="Tháng"
                            onChange={handleChange}
                            disabled={!isCustomPackage}
                            fullWidth
                            size="small"
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                            <MenuItem value={36}>36</MenuItem>
                        </Select>

                        {isCustomPackage && (
                            <>
                                <InputLabel id="select-label-maxFile"
                                    sx={{ marginTop: 2 }} >
                                    Số lượng file
                                </InputLabel>
                                <Select
                                    labelId="select-label-maxFile"
                                    id="select-maxFile"
                                    name="maxFile"
                                    value={customPackage.maxFile || ''}
                                    label="maxFile"
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                </Select>
                                <InputLabel id="select-label-maxUrl"
                                    sx={{ marginTop: 2 }}
                                >Số lượng Url</InputLabel>
                                <Select
                                    labelId="select-label-maxUrl"
                                    id="select-maxUrl"
                                    name="maxUrl"
                                    value={customPackage.maxUrl || ''}
                                    label="maxUrl"
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                </Select>
                            </>
                        )}
                    </Grid>

                    {/* Cột bên phải */}
                    {isCustomPackage && (
                        <Grid item xs={6}>
                            <InputLabel id="select-label-maxBot"
                                sx={{ marginTop: 2 }}
                            >
                                Số lượng bot
                            </InputLabel>
                            <Select
                                labelId="select-label-maxBot"
                                id="select-maxMessage"
                                name="maxBot"
                                value={customPackage.maxBot || ''}
                                label="maxBot"
                                onChange={handleChange}
                                fullWidth
                                size="small"
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                            </Select>
                            <InputLabel id="select-label-maxMessage"
                                sx={{ marginTop: 2 }}>
                                Số lượng tin nhắn
                            </InputLabel>
                            <Select
                                labelId="select-label-maxMessage"
                                id="select-maxMessage"
                                name="maxMessage"
                                value={customPackage.maxMessage || ''}
                                label="maxMessage"
                                onChange={handleChange}
                                fullWidth
                                size="small"
                            >
                                <MenuItem value={500}>500</MenuItem>
                                <MenuItem value={1000}>1000</MenuItem>
                                <MenuItem value={1500}>1500</MenuItem>
                                <MenuItem value={2500}>2500</MenuItem>
                                <MenuItem value={15000}>15000</MenuItem>
                                <MenuItem value={25000}>25000</MenuItem>
                            </Select>

                        </Grid>
                    )}
                </Grid>
            </Box>
        </div>
    );
};
