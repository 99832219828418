import { Box, Button, Card, CardContent } from '@mui/material';
import { useModal } from '../../../hooks/useModal';

const PackageCreate = ({setIsOpen}) => {
  const packageModal = useModal('create-package');

  const handleCloseModal = (data) => {
    setIsOpen(false)
  };
  return (
    <div>
      <Box>
        <Card>
          <CardContent>
            PackageCreate
            <Button onClick={() => handleCloseModal({ package: 'test' })} variant="contained">
              Close Modal
            </Button>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default PackageCreate;
