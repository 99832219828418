import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import SystemPackagePaymentDetail from './SystemPackagePaymentDetail';
import { SystemPackageItemDetail } from './SystemPackageItemDetail';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const SystemPackagePayment = ({ onPackageSelect, handleCloseModal,selectedPackage, setSelectedPackage }) => {
  const handleBack = () => {
    setSelectedPackage(null)
  }
  return (
    <Box height={'62vh'}>
      <Box onClick={handleBack} sx={{ cursor: 'pointer',transition: 'color 0.3s ease','&:hover': { color: '#205BD3'}}}>
        <ArrowBackIcon />
      </Box>
      <Grid sx={{ height: '100%' }} container spacing={2} flexGrow={1} >
        <Grid item xl={5} lg={5} md={6} xs={12} sm={12} alignItems={'center'}>
          <Card>
            <CardContent>
              <SystemPackageItemDetail onPackageSelect={onPackageSelect} handleCloseModal={handleCloseModal}  systemPackage={selectedPackage} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={7} lg={7} md={6} xs={12} sm={12} alignItems={'flex-end'}>
          <SystemPackagePaymentDetail   handleCloseModal={handleCloseModal} systemPackage={selectedPackage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SystemPackagePayment;
