import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import affiliation from '../../../services/affiliations';
import AffiliationTableItem from './AffiliationTableItem';
import { useSelector } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const AffiliationTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [totalCustomers, setTotalCustomers] = useState(0);

  useEffect(() => {
    loadAff();
  }, [page, rowsPerPage]);

  const loadAff = () => {
    setIsLoading(true);
    affiliation
      .getAffiliation({ page: page + 1, limit: rowsPerPage })
      .then((res) => {
        setData(res.data);
        setTotalCustomers(res.totalCustomers);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Paper>
      <TableContainer sx={{ height: '100%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Tên khách hàng</StyledTableCell>
              <StyledTableCell align="left">Số lượng gói đã mua</StyledTableCell>
              <StyledTableCell align="left">Tổng tiền (VNĐ)</StyledTableCell>
              <StyledTableCell align="left">Tiền thưởng (VNĐ)</StyledTableCell>
              <StyledTableCell align="left">Chi tiết</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <StyledTableCell colSpan={5} align="center">
                  <CircularProgress />
                </StyledTableCell>
              </TableRow>
            ) : (
              data?.map((aff) => <AffiliationTableItem key={aff.bonus} aff={aff} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalCustomers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default AffiliationTable;
