import { Box, CircularProgress } from '@mui/material';
import ChatInformationHeader from './ChatInformationHeader';
import ChatConversationMessages from './ChatConversationMessages';
import ChatConversationDetail from '../chat-detail/ChatConversationDetail';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import chatboxService from '../../../services/chatboxService';

const openConversationDetailLocalStorageKey = 'schat-open-conversation-detail';

const ChatboxInformation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const chatboxId = queryParams.get('chatboxId') || null;

  const [openConversationDetail, setOpenConversationDetail] = useState(
    localStorage.getItem(openConversationDetailLocalStorageKey) === 'true'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [chatbox, setChatbox] = useState({});

  useEffect(() => {
    loadChatbox();
  }, [chatboxId]);

  const loadChatbox = () => {
    if (!chatboxId || isLoading) {
      return;
    }
    chatboxService
      .getChatboxById(chatboxId)
      .then((res) => {
        console.log(res);

        setChatbox(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpenConversationDetail = () => {
    const open = !openConversationDetail;
    setOpenConversationDetail(open);
    localStorage.setItem(openConversationDetailLocalStorageKey, open);
  };

  return (
    <Box display={'flex'} flexDirection={'row'} flexGrow={1}>
      <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
        <ChatInformationHeader openConversationDetail={handleOpenConversationDetail} />
        {isLoading && (
          <Box display={'flex'} justifyContent={'center'} mt={3}>
            {' '}
            <CircularProgress
              sx={{ ml: 1 }}
              color="inherit"
              size={15}
              thickness={5}
              variant="indeterminate"
            ></CircularProgress>
          </Box>
        )}
        {chatbox?.messages && <ChatConversationMessages messages={chatbox?.messages} />}
      </Box>
      <Box hidden={!!openConversationDetail}>
        <ChatConversationDetail chatbox={chatbox} />
      </Box>
    </Box>
  );
};

export default ChatboxInformation;
