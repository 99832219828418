import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import icon from '../../../assets/media/register/stars.png';
import logo from '../../../assets/media/register/bannerAiB.png';
import link from '../../../assets/media/register/link.png';
import file from '../../../assets/media/register/file.png';
import mess from '../../../assets/media/register/mess.png';
import setting from '../../../assets/media/register/setting.png';
const features = [
  {
    id: 1,
    title: '1500 tin nhắn từ Schat/ tháng',
    icon: mess,
  },
  {
    id: 2,
    title: '1 file training chatbot',
    icon: file,
  },
  {
    id: 3,
    title: '1 URL gắn vào chatbot',
    icon: link,
  },
  {
    id: 4,
    title: (
        <>
          Cài đặt, setup chat bot
          {' '}<Typography sx={{fontFamily:'HarmonyOS_Sans_Bold',fontSize:19,color:'#fd2a33'}}>
          MIỄN PHÍ
          </Typography>
        </>
      ),
    icon: setting,
  },
];
export default function Sale() {
  return (
    <div
      style={{
        backgroundImage: `url(${logo}), linear-gradient(360deg, #130a2e 0%, #2a1f6d 100%)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '80vh',
        color: '#fff',
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            gap: 2,
            marginBottom: 10,
          }}
        >
          <Box
            component="img"
            src={icon}
            alt="icon"
            sx={{
              width: '100%',
              maxWidth: 40,
            }}
          />
          <Typography
            sx={{
              fontSize: 26,
              fontFamily: 'HarmonyOS_Sans_Black',
              textTransform: 'uppercase',
              textAlign: 'center',
            }}
          >
            Ưu đãi{' '}
            <span
              style={{
                color: '#ff009c',
              }}
            >
              {' '}
              DÀNH RIÊNG CHO BẠN
            </span>{' '}
            <br />
            KHI ĐĂNG KÍ TƯ VẤN TẠI ĐÂY
          </Typography>

          <Box
            component="img"
            src={icon}
            alt="icon"
            sx={{
              width: '100%',
              maxWidth: 40,
            }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: 19,
            fontFamily: 'HarmonyOS_Sans_Bold',
            marginTop: '-50px',
            marginBottom:10
          }}
          variant="body1"
          align="center"
        >
          Chỉ còn 299K/ THÁNG, áp dụng trong 3 THÁNG liên tiếp
        </Typography>
        <Grid container spacing={7} justifyContent="center">
          {features.map((feature, index) => (
            <Grid
              key={feature.id}
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                marginTop: index % 2 === 0 ? '80px' : 0,
                
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  textAlign: 'center',
                  borderRadius: 4,
                  position: 'relative',
                  minHeight: 150,
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center',
                  gap:1
                }}
              >
                <Box
                 component="img"
                 src={feature.icon}
                 alt="icon"
                 sx={{
                    width: '100%',
                    maxWidth: 60,
                  }}
                >
                </Box>
                <Typography variant="h6" component="div" fontWeight="bold">
                  {feature.title}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
