import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AffilitionTableItemDetail = ({ aff = [] }) => {
  return (
    <>
      {aff?.map((item,index) => (
        <>
          <StyledTableRow key={index}>
            <StyledTableCell>{index + 1}</StyledTableCell>
            <StyledTableCell>{item.info?.name}</StyledTableCell>
            <StyledTableCell>{item.info?.price?.toLocaleString('vi-VN')}</StyledTableCell>
            <StyledTableCell>{item.status}</StyledTableCell>
            <StyledTableCell>
              {new Date(item.createdAt).toLocaleDateString('vi-VN')}
            </StyledTableCell>
          </StyledTableRow>
        </>
      ))}
    </>
  );
};

export default AffilitionTableItemDetail;
