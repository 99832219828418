import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material';
import HeaderTabs from './HeaderTabs';
import HeaderUserMenu from './HeaderUserMenu';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import botService from '../../../services/botService';
import { botAction } from '../../../reducers/slices/botSlice';
import HeaderBotTabs from './HeaderBotTabs';

function Header() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { currentBot, currentBots } = useSelector((state) => state.bot);

  useEffect(() => {
    loadBots();
  }, []);

  const loadBots = () => {
    botService
      .getBots()
      .then((data) => {
        dispatch(botAction.setCurrentBots(data));
        console.log('jash');

        if (!currentBot?.id && data.length) {
          dispatch(botAction.setCurrentBot(data[0]));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 'none', zIndex: theme.zIndex.drawer + 1 }}
      variant="elevation"
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <HeaderTabs />
          <Box sx={{ flexGrow: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <HeaderBotTabs />
            <HeaderUserMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
