import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../services/authService';

export const getUserByToken = createAsyncThunk('auth/getUserByToken', async (_, thunkAPI) => {
  try {
    return await authService.getUserByToken();
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const login = createAsyncThunk('auth/login', async (data, thunkAPI) => {
  try {
    return await authService.login(data);
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
