import React from 'react'
import SystemPackagePayment from './SystemPackagePayment';

/**
* @author
* @function 
**/

export const SystemPackagePaymentCustom = ({handleCloseModal, selectedPackage,setSelectedPackage,onPackageSelect }) => {
 
  return (
    <div>
      <SystemPackagePayment onPackageSelect={onPackageSelect} handleCloseModal={handleCloseModal} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage} />
    </div>
  )
}
