import { Navigate } from 'react-router-dom';
import AuthGuard from './AuthGuard';
import Auth from '../pages/Auth';
import Login from '../containers/auth/Login';
import Register from '../containers/auth/Register';

const authRoute = {
  path: 'auth',
  element: <AuthGuard children={<Auth />} requiredAuth={false} />,
  children: [
    {
      path: '',
      element: <Navigate to="login" />,
      title: 'Đăng nhập',
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
  ],
};

export default authRoute;
