import { createSlice } from '@reduxjs/toolkit';
import { Component } from 'react';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {},
  reducers: {
    openModal(state, action) {
      const { name, component, configs, isOpen, onClose, data } = action.payload;
      state[name] = {
        name,
        component: component,
        configs: configs || {},
        isOpen: isOpen || false,
        onClose: onClose,
        data,
      };
    },

    closeModal(state, action) {
      const { name, data } = action.payload;
      if (state[name]) {
        state[name].isOpen = false;
        state[name].onClose(data);
      }
    },
  },
});

export const modalActions = modalSlice.actions;
export default modalSlice.reducer;
