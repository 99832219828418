import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import SystemPackageStore from './SystemPackageStore';
import SystemPackagePayment from './SystemPackagePayment';
import { useState } from 'react';
import { SystemPackagePaymentCustom } from './SystemPackagePaymentCustom';
import { systemPackageEnum } from '../../../../helpers/packageHelper';

const CreateNewPackage = ({ handleCloseModal }) => {
  const theme = useTheme();
  const [selectedPackage, setSelectedPackage] = useState(null);


  const renderPackageContent = () => {
    if (!selectedPackage) {
      return (
        <SystemPackageStore 
          handleCloseModal={handleCloseModal} 
          onPackageSelect={setSelectedPackage} 
        />
      );  
    }

    if (selectedPackage.name === systemPackageEnum.CUSTOM) {
      return (
        <SystemPackagePaymentCustom
          handleCloseModal={handleCloseModal}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          onPackageSelect={setSelectedPackage} 

        />
      );
    }

    return (
      <SystemPackagePayment
        handleCloseModal={handleCloseModal}
        selectedPackage={selectedPackage}
        setSelectedPackage={setSelectedPackage}
        onPackageSelect={setSelectedPackage} 
      />
    );
  };

  return (
    <Card sx={{ bgcolor: theme.palette.background.default, overflow: 'auto' }}>
      <CardHeader
        sx={{ pb: 0, '& .MuiCardHeader-title': { fontSize: 18 } }}
        title="Tạo gói mới"
        action={
          <IconButton onClick={handleCloseModal} sx={{ borderRadius: 2 }} color="error">
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent sx={{ height: '70vh', minHeight: 300 }}>
        <Card variant="outlined" sx={{ height: '100%', overflow: 'auto' }}>
          <CardContent>
            {renderPackageContent()}
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default CreateNewPackage;
