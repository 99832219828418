import { Box, Card, CardContent, CardHeader, Container, Grid } from '@mui/material';
import AccountInformation from '../containers/account/information/AccoutInformation';
import { Outlet } from 'react-router-dom';

const Account = () => {
  return (
    <Container maxWidth="lg">
      <Box display={'flex'} flexDirection={'row'} mt={3}>
        <Box width={700}>
          <AccountInformation />
        </Box>
        <Box width={'100%'} ml={3}>
          <Outlet />
        </Box>
      </Box>
    </Container>
  );
};

export default Account;
