const { styled } = require('@mui/material');

const Textarea = styled('textarea')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 400,
  letterSpacing: 1.2,
  fontSize: theme.typography.fontSize * 1.1,
  color: theme.palette.text.main,
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  '&:focus': {
    outline: `2px solid ${theme.palette.primary.light}`,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default Textarea;
