import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import PricingFeatures from './PricingFeatures';

const PricingCard = ({ title, price, features, color, hoverColor }) => {
  return (
    <Card
      sx={{
        '&:hover': {
          backgroundColor: hoverColor,
        },
      }}
    >
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant="h5" component="div" color={color} gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
          {price}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          VND/Tháng
        </Typography>
        <PricingFeatures features={features} />
        <Button variant="contained" color={color}>
          Mua ngay
        </Button>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
