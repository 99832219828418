import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserByToken } from '../reducers/actions/authAction';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children, requiredAuth, requiredAdmin }) => {
  const { user, isLoading, error } = useSelector((state) => state.auth);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!requiredAuth && user?.id) {
    return <Navigate to="/" />;
  }

  if (requiredAuth && !user?.id) {
    return <Navigate to="/auth" />;
  }

  if (requiredAdmin && user?.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return children;
};

export default AuthGuard;
