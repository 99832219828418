import { createTheme } from '@mui/material/styles';

const primaryColor = '#24417C';

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: '#FFC300',
    },
    background: {
      default: '#EAECF0',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#1D2939',
    },
  },
  typography: {
    typography: {
      fontFamily: 'Poppins, sans-serif',
    },
    fontSize: 13,
    h6: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h1: {
      fontWeight: 'bold',
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          boxShadow: 'none',
          boxSizing: 'border-box',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 24,
          '.MuiCardHeader-title': {
            fontWeight: 'bold',
            fontSize: 16,
            color: primaryColor,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'inherit',
          fontWeight: 500,
          marginBottom: 5,
        },
      },
    },
  },
});

export default theme;
