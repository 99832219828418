import UpdateUser from './UpdateUser';
import UserTable from './UserTable';

const User = () => {
  return (
    <div>
      <UserTable />
    </div>
  );
};

export default User;
