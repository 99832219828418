import { Avatar, Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setCurrentSocialConnect } from '../../../reducers/slices/socialConnectSlice';

const BotSocialConnectListItem = ({ item }) => { // Nhận item làm prop
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleSelect = () => {
    dispatch(setCurrentSocialConnect(item));
  };

  return (
    <Box onClick={handleSelect}>
      <Card
        variant="outlined"
        sx={{ '&:hover': { backgroundColor: theme.palette.action.hover }, cursor: 'pointer' }}
      >
        <CardContent sx={{ '&.MuiCardContent-root': { p: 2 } }}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Avatar src={item.avatar} variant="rounded" sx={{ width: 54, height: 54 }} />
              <Typography sx={{ ml: 1.5 }} variant="body1" fontWeight={500} fontSize={16}>
                {item.pageName}
                <Typography variant="subtitle2">ID: {item.pageID}</Typography> 
              </Typography>
            </Box>
            {item.type === 'facebook' ? (
              <Avatar
                src="https://cdn.jim-nielsen.com/ios/512/facebook-2019-05-21.png?rf=1024"
                variant="rounded"
                sx={{ width: 24, height: 24 }}
              />
            ) : (
              <Avatar
                src="https://cdn.jim-nielsen.com/ios/512/facebook-2019-05-21.png?rf=1024"
                variant="rounded"
                sx={{ width: 24, height: 24 }}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BotSocialConnectListItem;
