import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ChatConversationListItem from './ChatConversationListItem';
import botService from '../../../services/botService';
import { useLocation, useNavigate } from 'react-router-dom';

const ChatConversationList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const chatboxId = queryParams.get('chatboxId') || null;
  const botSelector = useSelector((state) => state.bot);
  const [isLoading, setIsLoading] = useState(false);
  const [bot, setBot] = useState(botSelector.currentBot);
  const [chatboxes, setChatboxes] = useState([]);

  useEffect(() => {
    setBot(botSelector.currentBot);
  }, [botSelector.currentBot]);

  useEffect(() => {
    if (bot?.id) {
      loadChatboxes();
    }
  }, [bot]);

  const loadChatboxes = () => {
    setIsLoading(true);
    botService
      .getChatboxesByBotId(bot.id)
      .then((res) => {
        setChatboxes(res.data);
        if (!chatboxId) {
          goToChatboxDetail(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const goToChatboxDetail = (chatbox) => {
    navigate(`?chatboxId=${chatbox?.id}`);
  };

  return (
    <Box flexGrow={1} overflow={'auto'} px={2}>
      {chatboxes?.length &&
        chatboxes.map(
          (chatbox, index) =>
            chatbox && (
              <ChatConversationListItem key={chatbox.id} chatbox={chatbox} index={index + 1} />
            )
        )}
    </Box>
  );
};

export default ChatConversationList;
