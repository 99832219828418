import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import icon from '../../../assets/media/register/stars.png';
import box1 from '../../../assets/media/register/box1.png';
import box2 from '../../../assets/media/register/box2.png';
import box3 from '../../../assets/media/register/box3.png';
import box4 from '../../../assets/media/register/box4.png';
import box5 from '../../../assets/media/register/box5.png';
import box6 from '../../../assets/media/register/box6.png';
import box7 from '../../../assets/media/register/box7.png';
import box8 from '../../../assets/media/register/box8.png';
import icon_r from '../../../assets/media/register/icon_r.png';
import icon_l from '../../../assets/media/register/icon_l.png';
import robot from '../../../assets/media/register/robot.png';

const features = [
  {
    id: 1,
    title: (
      <>
        <Typography variant="body1" component="span" fontWeight="bold">
          Sử dụng trí tuệ nhân tạo
        </Typography>{' '}
        để không chỉ trả lời các câu hỏi cơ bản mà còn có khả năng học hỏi từ những câu hỏi phức
        tạp. Do đó, Schat có thể thay thế cho nhân viên tư vấn khách hàng để tư vấn và chốt đơn 24/7
      </>
    ),
    icon: box1,
  },
  {
    id: 2,
    title: (
      <>
        Đưa ra hình ảnh và{' '}
        <Typography variant="body1" component="span" fontWeight="bold">
          thông tin sản phẩm
        </Typography>{' '}
        để hỗ trợ quá trình chăm sóc và bán hàng giúp khách hàng hình dung và hiểu rõ hơn về sản
        phẩm
      </>
    ),
    icon: box2,
  },
  {
    id: 3,
    title: (
      <>
        Tạo ra quy trình bán hàng chuẩn hóa 100% do AI thực hiện:{' '}
        <Typography variant="body1" component="span" fontWeight="bold">
          tự động lọc số điện thoại
        </Typography>{' '}
        & địa chỉ khách hàng,{' '}
        <Typography variant="body1" component="span" fontWeight="bold">
          chốt khách, gọi điện xác nhận thông tin khách hàng, lên đơn
        </Typography>{' '}
      </>
    ),
    icon: box3,
  },
  {
    id: 4,
    title: (
      <>
        <Typography variant="body1" component="span" fontWeight="bold">
        Tự động nhắn tin 
        </Typography>{' '}
        chăm sóc khách hàng sau bán, thực hiện các chiến dịch remarketing , hậu mãi bài bản
      </>
    ),
    icon: box4,
  },
  {
    id: 5,
    title: (
      <>
      <Typography variant="body1" component="span" fontWeight="bold">
        Theo dõi đơn hàng
        </Typography>{' '}
        trên hệ thống và{' '}
        <Typography variant="body1" component="span" fontWeight="bold">
        báo cáo tiến trình chuyển hàng 
        </Typography>{' '}
        tới tay khách hàng
      </>
    ),
    icon: box5,
  },
  {
    id: 6,
    title: (
      <>
        <Typography variant="body1" component="span" fontWeight="bold">
        Lưu lại toàn bộ lịch sử chat
        </Typography>{' '}
        và tiếp tục duy trì mạch câu chuyện liên tục ngay cả khi khách hàng thoát ra khỏi nền tảng chat 
      </>
    ),
    icon: box6,
  },
  {
    id: 7,
    title: (
      <>
       Khả năng tương tác với khách hàng{' '}
        <Typography variant="body1" component="span" fontWeight="bold">
        không giới hạn số lượng
        </Typography>{' '}
        thời gian và ngôn ngữ
        {' '}
        <Typography variant="body1" component="span" fontWeight="bold">
        (chat liên tục 24/7 với mọi ngôn ngữ)
        </Typography>
      </>
    ),
    icon: box7,
  },
  {
    id: 8,
    title: (
      <>
        Hỗ trợ cho việc bán hàng đa nền tảng nhờ khả năng {' '}
        <Typography variant="body1" component="span" fontWeight="bold">
        tích hợp vào nhiều nền tảng 
        </Typography>{' '}
        như Website, Facebook, Tiktok, Zalo, Whatsapp, ...
      </>
    ),
    icon: box8,
  },
];

export default function Features() {
  return (
    <div
      style={{
        backgroundColor: 'transparent',
        backgroundImage: 'linear-gradient(360deg, #2a1f6d 0%, #130a2e 100%)',
        minHeight: '100vh',
      }}
    >
      <Box
        component="img"
        src={icon_r}
        alt="icon_r"
        sx={{
          width: '100%',
          maxWidth: 100,
          float:'right',
          marginTop:'-20px',
          opacity: 0.3,
        }}
      />
      <Container>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            paddingTop: 8,
            gap: 2,
            marginBottom: 10,
          }}
        >
          <Box
            component="img"
            src={icon}
            alt="icon"
            sx={{
              width: '100%',
              maxWidth: 40,
            }}
          />
          <Typography
            sx={{ fontSize: 26, fontFamily: 'HarmonyOS_Sans_Black', textTransform: 'uppercase' }}
          >
            08 tính năng vượt trội của Schat
          </Typography>
          <Box
            component="img"
            src={icon}
            alt="icon"
            sx={{
              width: '100%',
              maxWidth: 40,
            }}
          />
        </Box>
        <Grid sx={{ marginTop: 4 }} container spacing={2}>
          {features.map((feature) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ position: 'relative', marginBottom: 10 }}
              key={feature.id}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: -15,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  color: '#fff',
                  zIndex: 1,
                  backgroundImage: 'linear-gradient(180deg, #fa273f 0%, #d404ea 100%)',
                  padding: '10px 15px',
                  borderRadius: '30px',
                }}
                component="img"
                src={feature.icon}
                alt={`icon-${feature.id}`}
              ></Box>
              <Paper
                elevation={3}
                style={{
                  padding: '80px 20px',
                  textAlign: 'center',
                  minHeight: 160,
                  borderRadius: '30px',
                }}
              >
                <Typography sx={{ fontFamily: 'HarmonyOS_Sans_Medium' }} variant="body1">
                  {feature.title}
                </Typography>{' '}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
     
      <Box
  component="img"
  src={icon_l}
  alt="icon_l"
  sx={{
    width: '100%',
    maxWidth: 100,
    marginTop: '0px',
    opacity: 0.3,
    position: 'relative', 
    zIndex: 1,
    marginTop:'-100px'
  }}
/>
<Box
  component="img"
  src={robot}
  alt="robot"
  sx={{
    width: '100%',
    maxWidth: 200,
    opacity: 0.8,
    position: 'absolute', 
    zIndex: 2, 
    marginTop:'-250px',
    marginLeft:'-80px'
  }}
/>

    </div>
  );
}
