import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const PricingFeatures = ({ features }) => {
  return (
    <Box sx={{ margin: 2 }}>
      <Divider sx={{ margin: 2 }} />
      {features.map((feature, idx) => (
        <Typography key={idx} variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
          {feature}
        </Typography>
      ))}
    </Box>
  );
};

export default PricingFeatures;
