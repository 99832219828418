import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  useTheme,
} from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ChatIcon from '@mui/icons-material/Chat';
import AddCardIcon from '@mui/icons-material/AddCard';
import { useNavigate } from 'react-router-dom';
import InboxIcon from '@mui/icons-material/Inbox';

const pages = [
  {
    title: 'Bots',
    icon: <SmartToyIcon sx={{ color: 'white' }} />,
    href: '/bots',
  },
  {
    title: 'Chats',
    icon: <ChatIcon sx={{ color: 'white' }} />,
    href: '/chats',
  },
  {
    title: 'Cards',
    icon: <AddCardIcon sx={{ color: 'white' }} />,
    href: '/prices',
  },
];

const sidebarWidth = 80;

const MiniSidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // const bgColor = theme.components.MuiAppBar.styleOverrides.root.backgroundColor;

  const goToPage = (href) => {
    navigate(href);
  };

  return (
    <Box width={sidebarWidth}>
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.primary.main,
            borderRight: 'none',
          },
        }}
        variant="permanent"
        open
      >
        <Toolbar />
        <Box width={sidebarWidth}>
          <List>
            <ListItem>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <InboxIcon sx={{ color: 'white' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MiniSidebar;
