import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useModal } from '../../../hooks/useModal';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import authToken from '../../../utils/authToken';

import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';

const BotSocialConnectCreateModal = () => {


  const createConnectModal = useModal('create-connect');
  const theme = useTheme();
  const handleCloseModal = (data) => {
    createConnectModal.closeModal(data || {});
  };

  const handleLogin = (type) => {
    const token = authToken.getTokenFromLocalStorage();
    if (token) {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.userId;
      const botId = createConnectModal.data.id;
      window.open(
        `https://schat.vn:8883/auth/${type}?userId=${userId}&botId=${botId}`,
        '_self',
      );
    }
    else{
      
    }
  };

  return (
    <Box>
      <Card>
        <CardHeader
          sx={{ '&.MuiCardHeader-root': { borderBottom: '1px solid #ccc', py: 2 } }}
          action={
            <IconButton onClick={handleCloseModal} aria-label="settings">
              <CloseIcon />
            </IconButton>
          }
          title="Thêm kết nối"
        />
        <CardContent sx={{ height: '500px' }}>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item sx={5} md={4} lg={4} xl={3}>
              <Card variant="outlined">
                <CardContent sx={{ p: 1 }}>
                  <Card
                    sx={{
                      '&:hover': { backgroundColor: theme.palette.action.hover },
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent sx={{ '&.MuiCardContent-root': { p: 2 } }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Avatar
                          src="https://cdn.jim-nielsen.com/ios/512/facebook-2019-05-21.png?rf=1024"
                          variant="rounded"
                          sx={{ width: 36, height: 36 }}
                        />
                        <Typography sx={{ ml: 1.5 }} variant="body1" fontWeight={500} fontSize={14}>
                          Facebook
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sx={7} md={8} lg={8} xl={9}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent sx={{ height: '100%' }}>
                  <CardContentHeader title={'Thêm tài khoản'} />
                  <Box
                    sx={{ height: '80%' }}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Box>
                      <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                        Kết nối tới Facebook
                      </Typography>
                      <Button variant="outlined" sx={{ px: 5, py: 1.5 }}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          onClick={() => handleLogin('facebook')}
                        >
                          <Avatar
                            src="https://cdn.jim-nielsen.com/ios/512/facebook-2019-05-21.png?rf=1024"
                            variant="rounded"
                            sx={{ width: 18, height: 18, mr: 1 }}
                          />
                          <Typography variant="body2">Đăng nhập qua Facebook</Typography>
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BotSocialConnectCreateModal;
