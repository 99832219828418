import Bot from '../pages/Bot';
import { Navigate } from 'react-router-dom';
import BotImageUpload from '../containers/bot/BotImageUpload';
import BotImagesStorage from '../containers/bot/BotImagesStorage';
import BotChatboxSetting from '../containers/bot/BotChatboxSetting';
import BotSetting from '../containers/bot/setting/BotSetting';
import BotIntegration from '../containers/bot/integration/BotIntegration';
import BotSocialConnect from '../containers/bot/social-connect/BotSocialConnect';
const botRoute = {
  path: 'bots',
  element: <Bot />,
  children: [
    {
      path: ':id',
      children: [
        {
          path: '',
          element: <Navigate to="setting" />,
        },
        {
          path: 'setting',
          element: <BotSetting />,
        },
        {
          path: 'upload-image',
          element: <BotImageUpload />,
        },
        {
          path: 'images',
          element: <BotImagesStorage />,
        },
        {
          path: 'chatbox-setting',
          element: <BotChatboxSetting />,
        },
        {
          path: 'integration',
          element: <BotIntegration />,
        },
        {
          path: 'social-connect',
          element: <BotSocialConnect />,
        },
      ],
    },
  ],
};

export default botRoute;
