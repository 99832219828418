import { Box, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { formatDate } from '../../../utils/dateUtil';

const ChatConversationMessagesItem = ({ message }) => {
  const theme = useTheme();

  if (message?.sender === 'user') {
    return (
      <Box mb={3} display={'flex'} justifyContent={'flex-start'}>
        <Box
          px={1.5}
          py={1}
          bgcolor={theme.palette.background.paper}
          width={'fit-content'}
          borderRadius={2}
          maxWidth={{
            xs: '80%',
            sm: '80%',
            md: '70%',
            lg: '70%',
            xl: '60%',
          }}
          display={'flex'}
          flexDirection={'column'}
        >
          <Typography sx={{ mb: 1 }} variant="body2" fontSize={14} color={theme.palette.text.main}>
            {message?.content}
          </Typography>
          <Typography
            alignSelf={'flex-start'}
            fontSize={10}
            variant="caption"
            color={theme.palette.text.main}
          >
            {formatDate(message?.createdAt)}
          </Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box mb={3} display={'flex'} justifyContent={'flex-end'}>
        <Box
          px={1.5}
          py={1}
          bgcolor={theme.palette.primary.light}
          width={'fit-content'}
          borderRadius={2}
          maxWidth={{
            xs: '80%',
            sm: '80%',
            md: '70%',
            lg: '70%',
            xl: '60%',
          }}
          display={'flex'}
          flexDirection={'column'}
        >
          <Typography
            sx={{ mb: 1 }}
            variant="body2"
            fontSize={14}
            color={theme.palette.primary.contrastText}
          >
            {message?.content.replace(/\$info\[(.*?)\]/, '')}
          </Typography>
          <Typography
            alignSelf={'flex-end'}
            fontSize={10}
            variant="caption"
            color={theme.palette.primary.contrastText}
          >
            {formatDate(message?.createdAt)}
          </Typography>
        </Box>
      </Box>
    );
  }
};

export default ChatConversationMessagesItem;
