import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Typography, Box, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { createUser } from '../../../reducers/slices/userSlice';

const CreateUser = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    avatar: null,
    name: '',
    phone: '',
    email: '',
    address: '',
    password: '',
    role: 'user',
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          avatar: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    dispatch(createUser(formData));
    setIsOpen(false);
  };

  return (
    <Paper sx={{ padding: 5 }}>
      <Container maxWidth="lg">
        <CloseIcon onClick={() => setIsOpen(false)} sx={{ cursor: 'pointer', fontSize: '24px', float: 'right' }} />
        <Typography variant="h6">Thêm mới khách hàng</Typography>
        <Grid container spacing={2}>
          {/* Cột 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Họ và tên"
              variant="outlined"
              margin="normal"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Số điện thoại"
              variant="outlined"
              margin="normal"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Mật khẩu"
              type="password"
              variant="outlined"
              margin="normal"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </Grid>

          {/* Cột 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              margin="normal"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Địa chỉ"
              variant="outlined"
              margin="normal"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </Grid>

          {/* Cột 3 */}
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              padding={2}
            >
              <label htmlFor="avatar-upload">
                <input
                  id="avatar-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: 'none' }} // Ẩn nút chọn file
                />
                <Box
                  component="span"
                  sx={{
                    width: 150,
                    height: 150,
                    borderRadius: '50%',
                    border: '2px solid grey',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    cursor: 'pointer',
                  }}
                >
                  {formData.avatar ? (
                    <Box
                      component="img"
                      src={formData.avatar}
                      alt="Avatar"
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Chọn ảnh
                    </Typography>
                  )}
                </Box>
              </label>
            </Box>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          style={{ marginTop: '16px' }}
        >
          Thêm mới
        </Button>
      </Container>
    </Paper>
  );
};

export default CreateUser;
