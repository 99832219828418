import { styled } from '@mui/system';

const FormGroup = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1), 
}))

export default FormGroup