import { Box, IconButton, Typography, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ChatInformationHeader = ({ openConversationDetail }) => {
  const theme = useTheme();

  return (
    <Box py={2} px={3} borderBottom={`1px solid ${theme.palette.divider}`}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <Typography variant="h6" color={'primary.main'}>
          Hội thoại
        </Typography>
        <IconButton onClick={openConversationDetail} color="info" sx={{ mr: -1 }}>
          <InfoOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatInformationHeader;
