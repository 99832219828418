import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import authService from '../../services/authService';
import { useLocation } from 'react-router-dom';
import OTPVerification from './OTPVerification';
import Banner from './detail/Banner';
import Features from './detail/Features';
import Sale from './detail/Sale';
import girl from '../../assets/media/register/girl.png';
import { Paper } from '@mui/material';

export default function Register() {
  const [showOTPVerification, setShowOTPVerification] = useState(false);
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [dataSubmit, setDataSubmit] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const introducerValue = searchParams.get('introduction_code');

  const topRef = useRef(null);
  const featuresRef = useRef(null);
  useEffect(() => {
    setErrors({});
    setGeneralError('');
    console.log('🚀 ~ Register ~ introducerValue:', introducerValue);
  }, []);

  const validateForm = (data) => {
    const errors = {};
    const emailPattern = /@/;
    const phonePattern = /^\d{10}$/;
    if (!data.get('email') || !emailPattern.test(data.get('email'))) {
      errors.email = 'Email phải có định dạng "@" và không được để trống.';
    }
    if (!data.get('phone') || !phonePattern.test(data.get('phone'))) {
      errors.phone = 'Số điện thoại phải có 10 chữ số và không được để trống.';
    }
    if (!data.get('fullName')) {
      errors.fullName = 'Tên đầy đủ không được để trống.';
    }
    if (!data.get('password')) {
      errors.password = 'Mật khẩu không được để trống.';
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const validationErrors = validateForm(data);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const dataSubmit = {
      name: data.get('fullName'),
      phone: data.get('phone'),
      email: data.get('email'),
      password: data.get('password'),
      introduction_code: introducerValue || '',
    };
    console.log('🚀 ~ handleSubmit ~ dataSubmit.introducerValue:', dataSubmit.introducerValue);

    setDataSubmit(dataSubmit);

    authService
      .getOTP(dataSubmit)
      .then(() => {
        setGeneralError('');
        setShowOTPVerification(true);
      })
      .catch((error) => {
        console.error('Registration error:', error);
        setGeneralError('Có lỗi xảy ra!');
      });
  };

  const handleResend = () => {
    handleSubmit(new Event('submit'));
  };

  const handleScrollToTop = () => {
    if (topRef && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div ref={topRef}>
        <Banner ref={topRef} />
        <div ref={featuresRef}>
          <Features />
        </div>
        <Sale />
        <div
          className=""
          style={{
            backgroundImage: `linear-gradient(360deg, rgb(42, 31, 109) 0%, rgb(22 14 51) 100%)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '100vh',
            color: '#fff',
          }}
        >
          <Container sx={{ minHeight: '100vh' }}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <Container>
                  <Box
                    component="img"
                    src={girl}
                    alt="banner"
                    sx={{
                      width: '100%',
                      maxWidth: 700,
                      marginTop: '-80px',
                    }}
                  ></Box>
                </Container>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Container component="main" maxWidth="xs">
                  {showOTPVerification ? (
                    <OTPVerification dataSubmit={dataSubmit} handleResend={handleResend} />
                  ) : (
                    <Paper sx={{ borderRadius: '30px' }}>
                      <Box
                        sx={{
                          marginTop: 8,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          color: '#000',
                          padding: '20px',
                        }}
                      >
                        <Typography
                          sx={{
                            borderBlockEnd: '1px solid #ccc',
                            padding: '10px 0',
                            width: '100%',
                            textAlign: 'center',
                            color: '#2e1ef9',
                            fontFamily: 'HarmonyOS_Sans_Bold',
                            textTransform: 'uppercase',
                          }}
                          component="h1"
                          variant="h5"
                        >
                          Đăng ký ngay
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="fullName"
                                label="Tên đầy đủ"
                                name="fullName"
                                autoComplete="full-name"
                                error={Boolean(errors.fullName)}
                                helperText={errors.fullName}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="phone"
                                label="Số điện thoại"
                                name="phone"
                                autoComplete="phone-number"
                                error={Boolean(errors.phone)}
                                helperText={errors.phone}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                placeholder="VD: abc@gmail.com"
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                required
                                fullWidth
                                name="password"
                                label="Mật khẩu"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                placeholder="VD: Abc123@@"
                                error={Boolean(errors.password)}
                                helperText={errors.password}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="Tôi đồng ý với các điều khoản"
                              />
                            </Grid>
                          </Grid>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                              mt: 3,
                              mb: 2,
                              color: '#fff',
                              backgroundImage: 'linear-gradient(90deg, #f92647 0%, #d303f3 100%)',
                              transition:
                                'background-image 0.3s ease-in-out, transform 0.3s ease-in-out',
                              '&:hover': {
                                transform: 'scale(1.05)',
                              },
                              fontFamily:'HarmonyOS_Sans_Black'
                            }}
                          >
                            Đăng ký
                          </Button>

                          <Grid container justifyContent="flex-end">
                            <Grid item>
                              <Link href="login" variant="body2">
                                Bạn đã có tài khoản? Đăng nhập
                              </Link>
                            </Grid>
                          </Grid>
                        </Box>
                        {generalError && (
                          <Alert severity="error" sx={{ mt: 2 }}>
                            {generalError}
                          </Alert>
                        )}
                      </Box>
                    </Paper>
                  )}
                </Container>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                gap: 2,
                padding: 10,
              }}
            >
              <Typography fontSize={'24px'} fontFamily={'HarmonyOS_Sans_Medium'}>
                Địa chỉ: Số 2, phố Đỗ Quang, phường Trung Hoà, quận Cầu Giấy, Hà Nội
              </Typography>
              <Typography fontSize={'24px'} fontFamily={'HarmonyOS_Sans_Medium'}>
                Hotline: 033.5055.068
              </Typography>
              <Typography fontSize={'24px'} fontFamily={'HarmonyOS_Sans_Medium'}>
                Email: schat.toolai@gmail.com
              </Typography>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
}
