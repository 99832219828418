import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import SettingProfile from './SettingProfile';
import ChangePassword from './ChangePassword';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { useSelector } from 'react-redux';

const AccountProfile = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Box height={'100%'}>
      <Grid sx={{ height: '100%' }} container spacing={2} flexGrow={1}>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <Card>
            <CardContent>
              <CardContentHeader
                title={'Thông tin tài khoản'}
                subtitle={'Quản lý thống tin tài khoản'}
              />
              <SettingProfile />
            </CardContent>
          </Card>

          {/* <Card sx={{ mt: 3, mb: 3 }}>
            <Typography variant="h6" component="h6" marginTop={2} marginLeft={3}>
              Đổi mật khẩu
            </Typography>
            <Typography variant="p" component="p" marginLeft={3}>
              Các thông tin của tài khoản
            </Typography>
            <ChangePassword />
          </Card> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountProfile;
