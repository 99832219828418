import AuthGuard from './AuthGuard';
import Layout from '../templates/Layout';
import botRoute from './botRoute';
import chatRoute from './chatRoute';
import profileRoute from './profileRoute';
import { Navigate } from 'react-router-dom';
import priceRoute from './priceRoute';
import accountRoute from './accountRoute';
import Admin from '../pages/Admin';
import adminRoute from './adminRoute';
import listBotRoute from './listBotRoute';

const layoutRoute = {
  path: '',
  element: <AuthGuard children={<Layout />} requiredAuth />,
  children: [
    {
      path: '',
      element: <Navigate to="bots" />,
    },
    adminRoute,
    botRoute,
    chatRoute,
    priceRoute,
    profileRoute,
    accountRoute,
    listBotRoute,
  ],
};

export default layoutRoute;
