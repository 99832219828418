import { Box, Card, CardContent, Modal } from '@mui/material';

const ModalCustom = ({
  open = false,
  onClose,
  size = 'medium',
  placement = 'center',
  children,
}) => {
  const getPlacementStyle = (placement) => {
    switch (placement) {
      case 'top':
        return { top: 50, left: '50%', transform: 'translate(-50%, 0)' };
      case 'center':
        return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
      case 'bottom':
        return { bottom: 50, left: '50%', transform: 'translate(-50%, 0)' };
      default:
        return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
    }
  };

  const getSizeStyle = (size) => {
    if (['small', 'medium', 'large', 'extraLarge'].includes(size)) {
      const sizeMap = {
        small: 400,
        medium: 800,
        large: 1000,
        extraLarge: 1200,
        fullWidth: '100%',
      };
      return { width: sizeMap[size] || sizeMap.medium };
    }
    return { width: size };
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        position="absolute"
        maxWidth={'90%'}
        maxHeight={'90%'}
        sx={{
          ...getPlacementStyle(placement),
          ...getSizeStyle(size),
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default ModalCustom;
