import React, { useEffect, useState, useRef } from 'react';
import { Paper, List, ListItem, ListItemText, Typography, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import chatboxService from '../../services/chatboxService';

// Styled components
const StyledPaper = styled(Paper)`
  height: 85vh;
  padding: 16px;
  position: relative;
`;

const StyledList = styled(List)`
  margin-top: 10px;
  height: calc(85vh - 80px);
  overflow-y: auto;
`;

const StyledListItem = styled(ListItem)`
  max-width: 500px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: fit-content !important;
  white-space: pre-wrap;

  &.message-bot {
    text-align: left;
    background-color: #e0f7fa;
    margin-left: auto;
    margin-right: 10px;
  }

  &.message-user {
    text-align: left;
    background-color: #fff9c4;
    margin-right: auto;
    margin-left: 10px;
  }
`;

const ChatDetail = ({ customer }) => {
  const { chatboxId } = useParams();
  const [chatbox, setChatbox] = useState({});
  const [messages, setMessages] = useState([]);
  const chatEndRef = useRef(null);

  useEffect(() => {
    loadData();
  }, [chatboxId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const loadData = () => {
    if (!chatboxId) return;
    chatboxService.getChatboxById(chatboxId).then((res) => {
      setChatbox(res);
      setMessages(res.messages);
      console.log(res.messages);
      scrollToBottom(); // Gọi hàm scrollToBottom sau khi tải dữ liệu
    });
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' }); // Cuộn xuống cuối container
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${hours}:${minutes} ${day}/${month}/${year}`;
  };

  return (
    <StyledPaper
      className="chat-detail"
      style={{ height: '85vh', padding: '16px', position: 'relative' }}
    >
      <div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 1 }}></div>
      <Typography variant="h6" gutterBottom>
        {chatbox.name || chatbox.id}
      </Typography>
      <Divider />
      <StyledList>
        {messages.map((message, index) => (
          <StyledListItem
            key={index}
            className={message.sender === 'bot' ? 'message-bot' : 'message-user'}
          >
            <ListItemText primary={message.content} secondary={formatTime(message.createdAt)} />
          </StyledListItem>
        ))}
        <div ref={chatEndRef} />
      </StyledList>
    </StyledPaper>
  );
};

export default ChatDetail;
