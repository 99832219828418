import {
  Avatar,
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

const AccountInformationMenu = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    {
      title: 'Gói cước',
      subtitle: 'Quản lý gói cước của bạn',
      icon: <CurrencyExchangeOutlinedIcon sx={{ color: theme.palette.success.light }} />,
      href: 'packages',
    },
    {
      title: 'Thông tin cá nhân',
      subtitle: 'Cập nhật thông tin cá nhân',
      icon: <AccountCircleOutlinedIcon sx={{ color: theme.palette.info.light }} />,
      href: 'profile',
    },
    {
      title: 'Affiliation',
      subtitle: 'Thông tin affiliation',
      icon: <AccountBalanceWalletIcon sx={{ color: theme.palette.warning.dark }} />,
      href: 'affiliation',
    },
    {
      title: 'Thống kê',
      subtitle: 'Số lượng tin nhắn, hiệu suất',
      icon: <BarChartOutlinedIcon sx={{ color: theme.palette.warning.light }} />,
      href: 'statistics',
    },
  ];

  const isActiveTab = (tab) => {
    return matchPath({ path: `/account/${tab.href}`, end: false }, location.pathname) !== null;
  };

  const goToPage = (tab) => {
    navigate(tab.href);
  };

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <MenuList>
        {tabs.map((tab) => (
          <MenuItem
            sx={{ py: 1.5, borderRadius: 2, mb: 1 }}
            selected={isActiveTab(tab)}
            key={tab.title}
            onClick={() => goToPage(tab)}
          >
            <ListItemIcon>
              <Avatar
                sx={{
                  width: 68,
                  height: 68,
                  backgroundColor: theme.palette.background.paper,
                  border: `2px solid ${theme.palette.grey[200]}`,
                }}
              >
                {tab.icon}
              </Avatar>
            </ListItemIcon>
            <ListItemText sx={{ ml: 2 }}>
              <Typography variant="body1" fontWeight={500} fontSize={16}>
                {tab.title}
              </Typography>
              <Typography variant="subtitle1" color={'text.secondary'} fontSize={13}>
                {tab.subtitle}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

export default AccountInformationMenu;
