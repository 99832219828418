import httpRequest from '../utils/httpRequest';

const botService = {
  getBots: () => {
    return httpRequest.get('/bots').then((res) => res.data);
  },

  getBotById: (id) => {
    return httpRequest.get(`/bots/${id}`).then((res) => res.data);
  },

  createBot: (body) => {
    return httpRequest.post('/bots', body).then((res) => res.data);
  },

  updateBot: (id, body) => {
    return httpRequest.put(`/bots/${id}`, body).then((res) => res.data);
  },

  tranningUrls: (botId, body) => {
    return httpRequest.post(`/bots/${botId}/train-urls`, body).then((res) => res.data);
  },

  tranningFiles: (botId, body) => {
    return httpRequest.post(`/bots/${botId}/train-files`, body).then((res) => res.data);
  },

  tranningImages: (botId, body) => {
    return httpRequest.post(`/bots/${botId}/train-images`, body).then((res) => res.data);
  },

  getChatboxesByBotId: (botId) => {
    return httpRequest.get(`/bots/${botId}/chatboxes`).then((res) => res.data);
  },

  addImage: (botId, body) => {
    return httpRequest.post(`/bots/${botId}/add-image`, body).then((res) => res.data);
  },

  updateChatboxConfig: (botId, body) => {
    return httpRequest.put(`/bots/${botId}/config`, body).then((res) => res.data);
  },
};

export default botService;
