import { Box, CssBaseline, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Main from './components/main/Main';
import Header from './components/header/Header';

const Layout = () => {
  return (
    <Box>
      <Header />
      <Main />
    </Box>
  );
};

export default Layout;
