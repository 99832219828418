import { Box, Container, CssBaseline, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

const Main = () => {
  return (
    <Box component={'main'}>
      <Toolbar disableGutters />
      <Outlet />
    </Box>
  );
};

export default Main;
