import { Box, Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import hljs from 'highlight.js';

const BotWebsiteIntegration = () => {
  const theme = useTheme();
  const botSelector = useSelector((state) => state.bot);
  const [bot, setBot] = useState(botSelector.currentBot);
  const [scriptCode, setScriptCode] = useState('');
  const [copyCode, setCopyCode] = useState('');
  const href = window.location.origin;

  useEffect(() => {
    if (botSelector.currentBot?.id) {
      setBot(botSelector.currentBot);
      exportScriptCode(botSelector.currentBot?.id);
    }
  }, [botSelector.currentBot]);

  const exportScriptCode = (botId) => {
    if (!botId) {
      return;
    }
    const code = `<script src="${href}/js/chat.js"></script>\n<script>chatAI('${botId}');</script>`;
    setCopyCode(code);
    setScriptCode(hljs.highlightAuto(code).value); // Sử dụng highlightAuto() để tô sáng cú pháp
  };

  const copyScript = () => {
    navigator.clipboard.writeText(copyCode);
  };

  return (
    <Card>
      <CardContent>
        <CardContentHeader title={'Tích hợp vào website'} />
        <Card
          variant="outlined"
          sx={{
            border: '1px solid #ccc',
            position: 'relative',
            mb: 1,
          }}
        >
          <CardContent>
            <pre style={{ margin: 0 }}>
              <code
                style={{ fontSize: 18 }}
                dangerouslySetInnerHTML={{ __html: scriptCode }}
              ></code>
            </pre>
          </CardContent>
          <Box position={'absolute'} right={10} top={10}>
            <Button onClick={copyScript} variant="contained">
              Copy
            </Button>
          </Box>
        </Card>
        <Typography variant="subtitle2" fontSize={14} color="text.secondary">
          Copy đoạn code trên và dán vào thẻ {'<body></body>'} trong website của bạn
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BotWebsiteIntegration;
