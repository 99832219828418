import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PackageLimitItem = ({ pack }) => {
  return (
    <>
      <StyledTableRow>
        <StyledTableCell align="left">
          {pack?.usage?.bot.total} / {pack?.limit?.maxBot}
        </StyledTableCell>
        <StyledTableCell align="left">
          {pack?.usage?.message.total} / {pack?.limit?.maxMessage}
        </StyledTableCell>
        <StyledTableCell align="left">
          {pack?.usage?.file.total} / {pack?.limit?.maxFile}
        </StyledTableCell>
        <StyledTableCell align="left">
          {pack?.usage?.url.total} / {pack?.limit?.maxUrl}
        </StyledTableCell>
        <StyledTableCell align="left">
          {pack?.time?.startDate
            ? new Date(pack.time.startDate).toLocaleDateString('en-GB', { timeZone: 'UTC' })
            : 'N/A'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {pack?.time?.endDate
            ? new Date(pack.time.endDate).toLocaleDateString('en-GB', { timeZone: 'UTC' })
            : 'N/A'}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default PackageLimitItem;
