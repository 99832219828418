import httpRequest from '../utils/httpRequest';
const END_POINT = '/packages';

const packageService = {
  getSystemPackages: () => {
    return httpRequest.get(`${END_POINT}/system`).then((res) => res.data);
  },

  getPackages: () => {
    return httpRequest.get(`${END_POINT}`).then((res) => res.data);
  },

  createPackage: (body) => {
    return httpRequest.post(`${END_POINT}`, body).then((res) => res.data);
  },

  updatePackage: (id, body) => {
    return httpRequest.put(`${END_POINT}/${id}/status`, body).then((res) => res.data);
  },
  getAllPackagesByAdmin: async (params) => {
    console.log(params);
    const res = await httpRequest.get(`${END_POINT}/by-admin`, { params });
    return res.data;
},
};

export default packageService;
