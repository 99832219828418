import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from '@mui/material';

import AffilitionTableItemDetail from './AffilitionTableItemDetail';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const AfiiliationTableDetail = ({ aff }) => {
  return (
    <Paper>
      <TableContainer sx={{ height: '100%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">STT</StyledTableCell>
              <StyledTableCell align="left">Tên gói</StyledTableCell>
              <StyledTableCell align="left">Giá(VNĐ)</StyledTableCell>
              <StyledTableCell align="left">Trạng thái</StyledTableCell>
              <StyledTableCell align="left">Ngày mua</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <AffilitionTableItemDetail key={aff.bonus} aff={aff.packages} />
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalCustomers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
};

export default AfiiliationTableDetail;
