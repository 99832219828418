import * as React from 'react';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import LayersIcon from '@mui/icons-material/Layers';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import Package from '../containers/admin/package/Package';
import User from '../containers/admin/user/User';
import Admin from '../pages/Admin';
import AuthGuard from './AuthGuard';
import { Typography } from '@mui/material';
import logo from '../assets/media/logos/logoschat.png'
import Dashboard from '../containers/admin/dashboard/Dashboard';
// Navigation structure
const NAVIGATION = [
  {
    kind: 'header',
    title: 'Chức năng chính',
  },
  {
    segment: 'dashboard',
    title: 'Trang chủ',
    icon: <DashboardIcon />,
  },
  {
    segment: 'user',
    title: 'Khách hàng',
    icon: <PeopleAltIcon />,
  },
  {
    segment: 'orders',
    title: 'Các gói',
    icon: <ShoppingCartIcon />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Quản lý dữ liệu',
  },
  {
    segment: 'reports',
    title: 'Thống kê',
    icon: <BarChartIcon />,
    children: [
      {
        segment: 'sales',
        title: 'Sales',
        icon: <DescriptionIcon />,
      },
      {
        segment: 'traffic',
        title: 'Traffic',
        icon: <DescriptionIcon />,
      },
    ],
  },
];

// Create theme for the dashboard
const Theme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

// PageContent displays content based on pathname
function PageContent({ pathname }) {
  return (
    <Box sx={{ marginTop: '-30px' }}>
      {pathname === '/' && <Dashboard />}
      {pathname === '/user' && <User />}
      {pathname === '/orders' && <Package />}
      {pathname !== '/user' && pathname !== '/orders' && (
        <Typography>Dashboard content for {pathname}</Typography>
      )}
    </Box>
  );
}

PageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

// Main dashboard layout component
function DashboardLayoutBasic({ window }) {
  const [pathname, setPathname] = React.useState('/'); // Default is 'user'

  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  const Window = window !== undefined ? window() : undefined;

  return (
    <AppProvider
      navigation={NAVIGATION}
      router={router}
      theme={Theme}
      window={Window}
      branding={{
        logo: <img src={logo} alt="Logo"/>, 
        title: 'Schat'
      }}
    >
      <DashboardLayout>
        <PageContent pathname={pathname} />
      </DashboardLayout>
    </AppProvider>
  );
}


// Define routing for the admin section
const adminRoute = {
  path: 'admin',
  element: (
    <AuthGuard requiredAdmin requiredAuth>
      <DashboardLayoutBasic />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <Navigate to="dashboard" />,
    },
    {
      path: 'user',
      element: <User />,
    },
    {
      path: 'package',
      element: <Package />,
    },
  ],
};

export default adminRoute;
