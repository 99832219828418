import { Box } from '@mui/material';
import AccountPackageList from './AccountPackageList';
import AccountUsageLimit from './AccountUsageLimit';

const AccountPackage = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box mb={3}>
        <AccountUsageLimit />
      </Box>
      <Box>
        <AccountPackageList />
      </Box>
    </Box>
  );
};

export default AccountPackage;
