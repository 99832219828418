import React from 'react';
import RingLoader from 'react-spinners/RingLoader';

const Loading = ({ loading, color = '#205BD3', size = 85 }) => {
  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 9999, // Ensure it's on top
  };

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust opacity as needed
    zIndex: 9998, // Slightly lower than the loader
  };

  return (
    <div style={overlayStyle}>
      <RingLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={size}
        aria-label="RingLoader"
        data-testid="RingLoader"
      />
    </div>
  );
};

export default Loading;