import { Box, Card, CardContent, Grid } from '@mui/material';
import ListBotAllHeader from './ListBotAllHeader';
import ListBotItem from './ListBotItem';
import { useEffect, useState } from 'react';
import botService from '../../../services/botService';
import { useModal } from '../../../hooks/useModal';
import { useSelector } from 'react-redux';

const ListBotAll = () => {
  const botSelector = useSelector((state) => state.bot);
  const [isLoading, setIsLoading] = useState(false);
  const [bots, setBots] = useState([]);

  useEffect(() => {
    if (botSelector.currentBots) {
      setBots(botSelector.currentBots);
    }
  }, [botSelector.currentBots]);

  return (
    <Box>
      {/* <Box>
        <ListBotAllHeader />
      </Box> */}
      <Box sx={{ mt: 3 }}>
        <Card sx={{ minHeight: '60vh' }}>
          <CardContent sx={{ height: '100%' }}>
            <Box sx={{}}>
              <Grid container spacing={2}>
                {bots?.length &&
                  bots.map((bot) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={bot.id}>
                        <ListBotItem bot={bot} />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ListBotAll;
