import { Box, Card, CardContent, Grid, Tab, Typography } from '@mui/material';
import SystemPackageItem from './SystemPackageItem';
import packageService from '../../../../services/packageService';
import { useEffect, useState } from 'react';
import TabsCustom from '../../../../components/tabs/TabsCustom';
import TabCustom from '../../../../components/tabs/TabCustom';
import Loading from '../../../../utils/loading';

const SystemPackageStore = ({onSystemPackages, handleCloseModal,onPackageSelect}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(1);
  const [systemPackages, setSystemPackages] = useState([]);
  const [activePackages, setActivePackages] = useState([]);
  
  useEffect(() => {
    loadSystemPackages();
  }, []);

  useEffect(() => {
    switch (value) {
      case 1:
        setActivePackages(systemPackages.filter((p) => p.duration === 1));
        break;
      case 6:
        setActivePackages(systemPackages.filter((p) => p.duration === 6));
        break;
      default:
    }
  }, [value, systemPackages]);

  const loadSystemPackages = () => {
    setLoading(true);
    packageService
      .getSystemPackages()
      .then((res) => {
        setSystemPackages(res.filter((p) => p.name !== 'free'));
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //
  const handlePurchaseClick = (selectedPackage) => {
    if (onPackageSelect) {
      onPackageSelect(selectedPackage);
    }
  };
  if (loading) return <div>
    <Loading loading={loading} />
  </div>;
  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        mb={3}
        justifyContent={'space-between'}
      >
        <Typography variant="h6" color={'text.primary'}>
          Chọn gói
          <Typography variant="body2" color={'text.secondary'}>
            Chọn gói có sẵn hoặc gói tùy chọn
          </Typography>
        </Typography>
        <TabsCustom value={value} onChange={handleChange}>
          <TabCustom value={1} sx={{ mr: 0.5 }} label="1 tháng" />
          <TabCustom value={6} label="6 tháng" />
        </TabsCustom>
      </Box>
      <Box>
        <Grid container spacing={2}>
          {activePackages.map((p) => (
            <Grid key={p.id} item xs={12} sm={6} lg={3} xl={3}>
              <SystemPackageItem  systemPackage={p} onPurchaseClick={handlePurchaseClick} />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <SystemPackageItem  handleCloseModal={handleCloseModal} systemPackage={{ name: 'custom',basePrice:'',price:"" }}  onPurchaseClick={handlePurchaseClick} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SystemPackageStore;
