import { Box } from '@mui/material';
import MainTitle from '../../../components/partials/MainTitle';
import BotWebsiteIntegration from './BotWebsiteIntegration';

const BotIntegration = () => {
  return (
    <Box>
      <MainTitle title="Tích hợp bot" />
      <BotWebsiteIntegration />
    </Box>
  );
};

export default BotIntegration;
