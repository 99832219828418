import { toast } from 'react-toastify';

const toastMessage = {
  showSuccess: (message) => {
    toast.success(message || 'Thao tác thành công!');
  },
  showError: (message) => {
    toast.error(message || 'Có lỗi xảy ra!');
  },
};

export default toastMessage;
