import { Box, Typography } from '@mui/material';

const CardContentHeader = ({ title, titleSize, subtitle, sx, action }) => {
  return (
    <Box
      sx={{ mb: 2, ...sx }}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={subtitle ? 'flex-start' : 'center'}
    >
      <Typography variant="h7" fontWeight={'bold'} fontSize={titleSize}>
        {title}
        {subtitle && (
          <>
            <br />
            <Typography variant="body2" sx={{ marginTop: 0.5 }} color={'text.secondary'}>
              {subtitle}
            </Typography>
          </>
        )}
      </Typography>
      <Box>{action}</Box>
    </Box>
  );
};

export default CardContentHeader;
