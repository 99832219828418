import { Box, List, useTheme } from '@mui/material';
import ChatConversationMessagesItem from './ChatConversationMessagesItem';
import { useEffect, useRef } from 'react';

const ChatConversationMessages = ({ messages }) => {
  const scrollToBottomRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    scrollToBottomRef.current.scrollTop = scrollToBottomRef.current.scrollHeight;
  }, [messages, scrollToBottomRef]);

  return (
    <Box
      ref={scrollToBottomRef}
      px={3}
      pt={3}
      display={'flex'}
      flexDirection={'column'}
      overflow={'auto'}
      height={'100%'}
      bgcolor={theme.palette.background.default}
      sx={{ scrollBehavior: 'smooth' }}
    >
      {messages.map((message) => (
        <ChatConversationMessagesItem key={message.id} message={message} />
      ))}
    </Box>
  );
};

export default ChatConversationMessages;
