import { styled, Tabs } from '@mui/material';

const TabsCustom = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ style: { display: 'none' } }} />
))(({ theme }) => ({
  minHeight: '12px',
  padding: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(1),
}));

export default TabsCustom;
