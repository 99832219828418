import { Card, CardHeader } from '@mui/material';
import React from 'react';
import AffiliationTable from './AffiliationTable';

export default function AccountAffiliation() {
  return (
    <div>
      <Card sx={{ height: 200 ,marginBottom: 3}} >
        <CardHeader title="Tổng quan" />
      </Card>
      <Card>
        <CardHeader title="Thông tin chi tiết" />
        <AffiliationTable />
      </Card>
    </div>
  );
}
