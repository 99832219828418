import { Grid } from '@mui/material';
import MainTitle from '../components/partials/MainTitle';
import ProfileInformation from '../containers/profile/ProfileInformation';
import { Card, CardContent } from '@mui/material';
import FormGroup from '../components/forms/FormGroup';
import FormLabel from '../components/forms/FormLabel';

const Profile = () => {
  return (
    <>
      <MainTitle title="Thông tin cá nhân" />
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xl={6} lg={6} md={6} sm={12}>
          <ProfileInformation />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12}>
          <Card>
            <CardContent>
              <FormGroup>
                <FormLabel>Thư viện</FormLabel>
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
