import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  CircularProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Pagination,
  TablePagination,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPackages } from '../../../reducers/slices/packageSilce';
import { fetchUsers } from '../../../reducers/slices/userSlice';
import PackageItem from './PackageItem';
import ModalCustom from '../../../components/modals/ModalCustom';
import PackageCreate from './PackageCreate';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Package = () => {
  const [status, setStatus] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [packageCode, setPackageCode] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const dispatch = useDispatch();
  const { packages, currentPage, totalPages } = useSelector((state) => state.packages);

  useEffect(() => {
    dispatch(fetchPackages({ page: currentPage, limit: 5 }));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (searchTerm.length > 1) {
      setLoading(true);
      dispatch(fetchUsers({ query: searchTerm }))
        .then((response) => {
          setUserOptions(response.payload.users || []);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [searchTerm, dispatch]);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchPackages({ page: currentPage, userId: selectedUserId, status, packageCode }));
    }
  }, [selectedUserId, status, packageCode, dispatch, currentPage]);

  useEffect(() => {
    dispatch(
      fetchPackages({ page: currentPage, userId: selectedUserId || '', status, packageCode })
    );
  }, [status, packageCode, selectedUserId, dispatch, currentPage]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSearchChange = (event, value) => {
    setSelectedUserId(value ? value.id : null);
  };

  const handlePackageCodeChange = (event) => {
    setPackageCode(event.target.value);
  };

  const handlePageChange = (event, value) => {
    dispatch(fetchPackages({ page: value, userId: selectedUserId || '', status, packageCode }));
  };
  const handleClick = () => {
    setIsOpen(true);
  };
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <TextField
          label="Mã giao dịch"
          variant="outlined"
          value={packageCode}
          onChange={handlePackageCodeChange}
          sx={{ marginRight: 2, width: { xs: '100%', sm: 200 } }}
        />

        <FormControl sx={{ minWidth: 200, marginRight: 2 }}>
          <InputLabel id="status-select-label">Trạng thái</InputLabel>
          <Select
            labelId="status-select-label"
            value={status}
            label="Trạng thái"
            onChange={handleStatusChange}
          >
            <MenuItem value="active">Thành công</MenuItem>
            <MenuItem value="waiting">Đang chờ</MenuItem>
            <MenuItem value="reject">Đã hủy</MenuItem>
          </Select>
        </FormControl>

        <Autocomplete
          sx={{ width: 400, marginRight: 2 }}
          getOptionLabel={(option) => option.name || ''}
          options={userOptions}
          loading={loading}
          onChange={handleSearchChange}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={option.avatar} sx={{ mr: 1 }} />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tìm kiếm khách hàng"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            whiteSpace: 'nowrap',
          }}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Tạo mới
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Mã giao dịch</StyledTableCell>
              <StyledTableCell align="center">Khách hàng</StyledTableCell>
              <StyledTableCell align="center" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                Tên gói
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                Thời hạn (tháng)
              </StyledTableCell>
              <StyledTableCell align="center">Giá (VND)</StyledTableCell>
              <StyledTableCell align="center">Trạng thái </StyledTableCell>
              <StyledTableCell align="center">Chức năng</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages &&
              packages
                .filter((pack) => pack.owner !== null)
                .map((pack, index) => <PackageItem key={pack.id} pack={pack} index={index} />)}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Phân trang */}
      {/* <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalPages}
        // rowsPerPage={pageSize}
        page={currentPage - 1}
        onPageChange={handlePageChange}
        // onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
       <ModalCustom open={isOpen} onClose={handleClose} size="large" placement="center">
        <PackageCreate setIsOpen={setIsOpen} />
      </ModalCustom>
    </Container>
  );
};

export default Package;
