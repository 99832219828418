import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import banner from '../../../assets/media/register/back.png';
import logo from '../../../assets/media/register/logo.png';
import time from '../../../assets/media/register/247.png';

export default function Banner() {
  return (
    <div
      style={{
        backgroundColor: 'transparent',
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        minHeight:'100vh'
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            paddingTop: 8,
            gap: 1,
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="logo"
            sx={{
              width: '100%',
              maxWidth: 200,
              margin: '0 auto',
            }}
          />
          <Typography
            sx={{
              marginBottom: '-25px',
              fontFamily: 'HarmonyOS_Sans_Black',
              textTransform: 'uppercase',
              textAlign:'center'
            }}
            variant="h3"
            component="h1"
            gutterBottom
          >
            Nền tảng chatbot ai
          </Typography>
          <Box
            component="img"
            src={time}
            alt="logo"
            sx={{
              width: '100%',
              maxWidth: 500,
              margin: '0 auto',
            }}
          />
          <Typography
            sx={{
              fontSize: 19,
              maxWidth: 800,
              lineHeight: 1.5,
              fontStyle: 'italic',
              fontFamily: 'HarmonyOS_Sans_Regular_Italic',
            }}
            variant="body"
            align="center"
          >
            Schat là nền tảng chatbot <strong>sử dụng công nghệ trí tuệ nhân tạo A.I</strong> để hỗ
            trợ khách hàng <strong>tư vấn, chốt đơn 24/7</strong> trên các nền tảng như Website,
            Facebook, Tiktok, Zalo…. Schat được xây dựng để trở thành nhân viên sale hoặc chăm sóc
            khách hàng chuyên nghiệp.
          </Typography>
          <Typography
            sx={{
              fontSize: 19,
              border: '1px solid #fff',
              borderRadius: '20px',
              padding: '8px 20px',
              marginTop: 2,
              fontFamily:'HarmonyOS_Sans_Bold'
            }}
            variant="body1"
            align="center"
          >
            Có Schat, Bạn có thể yên tâm đi ngủ mà không sợ khách hàng phải chờ đợi lâu!
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
