import { createSlice } from '@reduxjs/toolkit';

export const socialConnectSlice = createSlice({
  name: 'socialConnect',
  initialState: {
    currentSocialConnect: null,
  },
  reducers: {
    setCurrentSocialConnect: (state, action) => {
      state.currentSocialConnect = action.payload;
    },
  },
});

export const { setCurrentSocialConnect } = socialConnectSlice.actions;
export default socialConnectSlice.reducer;
