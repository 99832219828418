import {
  Avatar,
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { setCurrentSocialConnect } from '../../../reducers/slices/socialConnectSlice';
import { useEffect, useState } from 'react';
import botService from '../../../services/botService';
import userService from '../../../services/userService';

const BotSocialConnectDetail = ({ currentSocialConnect }) => {
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(setCurrentSocialConnect(null));
  };

  const [bots, setBots] = useState([]);

  useEffect(() => {
    const fetchBots = async () => {
      try {
        const res = await botService.getBots();
        setBots(res);
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    };

    fetchBots();
  }, []);

  const handleSave = (botId) => {
    const data = {
      pageId: currentSocialConnect.pageID,
      botId: botId,
    };
    if (!data.botId || !data.pageId) {
      alert('vui lòng chọn bot');
    }
    userService
      .updateBot(data)
      .then((res) => {
       console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
       
      });
  };
  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} mb={2}>
        <ArrowBackIcon onClick={handleBack} sx={{ cursor: 'pointer', mr: 1, fontSize: 18 }} />
        <Typography variant="h7" fontWeight={'bold'}>
          Chi tiết
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <CardContentHeader title={'Thông tin'} titleSize={14} />
            <Box>
              <Avatar
                src={currentSocialConnect.avatar}
                sx={{ width: 100, height: 100 }}
                variant="rounded"
              />
              <Box display={'flex'} alignItems={'center'} mt={2}>
                <Typography variant="h7" fontSize={15} fontWeight={'bold'}>
                  Loại kết nối:
                </Typography>
                <Typography variant="body2" fontSize={15} ml={1}>
                  Facebook
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} mt={1}>
                <Typography variant="h7" fontSize={15} fontWeight={'bold'}>
                  Social ID:
                </Typography>
                <Typography variant="body2" fontSize={15} ml={1}>
                  {currentSocialConnect.pageID}
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} mt={1}>
                <Typography variant="h7" fontSize={15} fontWeight={'bold'}>
                  Tên:
                </Typography>
                <Typography variant="body2" fontSize={15} ml={1}>
                  {currentSocialConnect.pageName}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <CardContentHeader title={'Cài đặt'} titleSize={14} />
            <Box>
              <Formik
                initialValues={{ botId: '' }}
                onSubmit={(values) => {
                  handleSave(values.botId);
                }}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <FormGroup sx={{ mb: 3 }}>
                      <FormLabel required>Chọn bot</FormLabel>
                      <Field
                        as={Select}
                        name="botId"
                        fullWidth
                        displayEmpty
                        value={values.botId}
                        onChange={handleChange}
                      >
                        {bots.map((bot) => (
                          <MenuItem
                            sx={{ display: 'flex', alignItems: 'center' }}
                            key={bot.id}
                            value={bot.id}
                          >
                            <Typography variant="body2">{bot.name}</Typography>
                          </MenuItem>
                        ))}
                      </Field>
                    </FormGroup>
                    <Box display="flex" justifyContent="flex-end">
                      <Button variant="outlined" color="secondary" sx={{ mr: 1 }}>
                        Hủy
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                      >
                        Lưu
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BotSocialConnectDetail;
