import ListBotAll from '../containers/list-bot/all/ListBotAll';
import ListBot from '../pages/ListBot';

const listBotRoute = {
  path: '/list-bot',
  element: <ListBot />,
  children: [
    {
      path: '',
      element: <ListBotAll />,
    },
  ],
};

export default listBotRoute;
