import { useEffect, useState } from 'react';
import { Avatar, Box, Button, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import ConfirmDialog from '../../../hooks/confirmDialog';
import ModalCustom from '../../../components/modals/ModalCustom';
import PackageLimit from './PackageLimit';
import { useDispatch } from 'react-redux';
import { updatePackage } from '../../../reducers/slices/packageSilce';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PackageItem = ({ pack, index, currentPage, limit, onDelete }) => {
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null); 

  const handleClose = () => setIsOpen(false);
  if (!pack) return null;


  const handleShow = () => {
    setIsOpen(true);
  };

  const handleActivate = () => {
    setConfirmAction('activate');
    setOpenConfirmDialog(true);
  };

  const handleConfirmActivate = () => {
    const status = { status: 'active' };
    const id = pack.id;
    dispatch(updatePackage({ id, status }));
    handleCloseConfirmDialog();
  };

  const handleCancelAction = () => {
    setConfirmAction('cancel');
    setOpenConfirmDialog(true); 
  };

  const handleConfirmCancel = () => {
    handleCloseConfirmDialog(); 
    const status = { status: 'reject' };
    const id = pack.id;
    dispatch(updatePackage({ id, status }));
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setConfirmAction(null); 
  };

  return (
    <>
      <StyledTableRow>
        <StyledTableCell align="center">{pack.code}</StyledTableCell>
        <StyledTableCell align="center">
          <Box display="flex" alignItems="center" justifyContent="left">
            <Avatar src={pack.owner?.avatar} sx={{ mr: 2 }} />
            {pack.owner?.name}
          </Box>
        </StyledTableCell>
        <StyledTableCell align="center">{pack.info?.name}</StyledTableCell>
        <StyledTableCell align="center">{pack.info?.duration}</StyledTableCell>
        <StyledTableCell align="center">
          {pack.info ? pack.info?.price.toLocaleString('vi-VN') : 'Đang cập nhật...'}
        </StyledTableCell>
        <StyledTableCell align="center">
          {pack?.status ? pack?.status : 'Đang cập nhật...'}
        </StyledTableCell>
        <StyledTableCell align="center">
          {pack.status === 'active' && (
            <>
              <Button variant="contained" color="secondary" onClick={handleShow}>
                Xem
              </Button>
              <Button variant="contained" color="secondary" onClick={handleCancelAction} sx={{ ml: 1 }}>
                Hủy
              </Button>
            </>
          )}
          {pack.status === 'waiting' && (
            <>
              <Button variant="contained" color="primary" onClick={handleActivate}>
                Kích hoạt
              </Button>
              <Button variant="contained" color="secondary" onClick={handleCancelAction} sx={{ ml: 1 }}>
                Hủy
              </Button>
            </>
          )}
          {pack.status === 'reject' && (
            <Button variant="contained" color="primary" onClick={handleActivate}>
              Kích hoạt
            </Button>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <ModalCustom open={isOpen} onClose={handleClose} size="large" placement="center">
        <PackageLimit idOwner={pack.owner?.id} setIsOpen={setIsOpen} />
      </ModalCustom>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={confirmAction === 'activate' ? handleConfirmActivate : handleConfirmCancel}
        title={confirmAction === 'activate' ? "Xác nhận kích hoạt" : "Xác nhận hủy"}
        subtitle={confirmAction === 'activate' ? "Kích hoạt" : "Đồng ý"}
        content={confirmAction === 'activate' ? `Bạn có chắc chắn muốn kích hoạt gói ${pack.info.name}?` : `Bạn có chắc chắn muốn hủy gói ${pack.info.name}?`}
      />
    </>
  );
};

export default PackageItem;
