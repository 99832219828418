import { Box, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../reducers/slices/modalSlice';

const ModalConfigs = () => {
  const modals = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const handleCloseModal = (name, data, onClose) => {
    dispatch(modalActions.closeModal({ name, data }));
    if (onClose) {
      onClose(data);
    }
  };
  return (
    <div>
      {modals &&
        Object.keys(modals).map((key) => {
          const modal = modals[key];
          const { name, component: Component, configs, isOpen, onClose, data } = modal;
          const { size = 'lg', placement = 'top' } = configs;

          const sizeMap = {
            sm: '400px',
            md: '800px',
            lg: '1000px',
            xl: '1200px',
            xxl: '1400px',
            full: '100%',
          };

          const placementMap = {
            top: {
              top: '0',
              left: '50%',
              transform: 'translate(-50%, 0)',
            },
            center: {
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },
            bottom: {
              bottom: '0',
              left: '50%',
              transform: 'translate(-50%, 0)',
            },
          };

          return (
            <Modal
              sx={{ overflow: 'scroll', height: '100vh' }}
              key={key}
              open={isOpen}
              onClose={() => handleCloseModal(name, data, onClose)}
            >
              <Box
                sx={{
                  position: 'absolute',
                  py: 5,
                  width: sizeMap[size] || size,
                  ...placementMap[placement],
                }}
              >
                {<Component />}
              </Box>
            </Modal>
          );
        })}
    </div>
  );
};

export default ModalConfigs;
