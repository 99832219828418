import { getUserByToken } from '../actions/authAction';
import authToken from '../../utils/authToken';
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    isLoading: true,
    token: authToken.getTokenFromLocalStorage(),
    error: null,
  },
  reducers: {
    logout: (state) => {
      authToken.removeTokenFromLocalStorage();
      state.user = {};
      state.token = null;
      state.isLoading = false;
      state.error = null;
    },

    login: (state, action) => {
      if (action.payload) {
        authToken.setTokenToLocalStorage(action.payload.token);
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isLoading = false;
        state.error = null;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserByToken.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUserByToken.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getUserByToken.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

const { reducer, actions } = authSlice;
export const { logout, login } = actions;
export default reducer;
