import { createSlice } from '@reduxjs/toolkit';

const botSlice = createSlice({
  name: 'bot',
  initialState: {
    currentBot: {},
    currentBots: [],
  },
  reducers: {
    setCurrentBot: (state, action) => {
      state.currentBot = action.payload;
    },

    setCurrentBots: (state, action) => {
      state.currentBots = action.payload;
    },

    addToCurrentBots: (state, action) => {
      state.currentBots.unshift(action.payload);
    },
  },
});

const { reducer, actions } = botSlice;
export const botAction = actions;
export default reducer;
