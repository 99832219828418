import Chat from '../pages/Chat';
import ChatDetail from '../containers/chat/ChatDetail';

const chatRoute = {
  path: 'chats',
  element: <Chat />,
  children: [
    {
      path: 'bot/:botId/chatboxes/:chatboxId',
      element: <ChatDetail />,
    },
  ],
};

export default chatRoute;
