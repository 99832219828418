import React, { useState, useEffect } from 'react';
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import userService from '../../../services/userService';
import PackageLimitItem from './PackageLimitItem';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const PackageLimit = ({idOwner}) => {
  const [limit, setLimit] = useState([]);
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    loadUsageLimit();
  }, []);

  const loadUsageLimit = () => {
    setIsLoading(true);
    userService
      .getUsageLimit(idOwner)
      .then((res) => {
        setLimit(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Paper>
      <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Bot</StyledTableCell>
              <StyledTableCell align="left">Tin nhắn</StyledTableCell>
              <StyledTableCell align="left">File</StyledTableCell>
              <StyledTableCell align="left">Url</StyledTableCell>
              <StyledTableCell align="left">Ngày mua</StyledTableCell>
              <StyledTableCell align="left">Ngày hết hạn</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* { limit?.map((pack, index) => ( */}
                <PackageLimitItem
                  pack={limit}
                />
              {/* ))
            } */}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PackageLimit;
