import { useState } from 'react';
import { Avatar, Box, Button, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import ConfirmDialog from '../../../hooks/confirmDialog'; // Nhập ConfirmDialog
import userService from '../../../services/userService';
import ModalCustom from '../../../components/modals/ModalCustom';
import UpdateUser from './UpdateUser';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UserTableItem = ({ user, index, currentPage, limit, onDelete }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  if (!user) return null;

  const handleClick = () => {
    setIsOpen(true)
  };

  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const stt = currentPage * limit + (index + 1);
  return (
    <>
      <StyledTableRow>
        <StyledTableCell align="center">{stt}</StyledTableCell>
        <StyledTableCell align="center">
          <Box display="flex" alignItems="center" justifyContent="left">
            <Avatar src={user.avatar} sx={{ mr: 2 }} />
            {user.name}
          </Box>
        </StyledTableCell>

        <StyledTableCell align="center">{user.email}</StyledTableCell>
        <StyledTableCell align="center">{user.phone}</StyledTableCell>
        <StyledTableCell align="center">
          {user.address ? user.address.street : 'Đang cập nhật...'}
        </StyledTableCell>

        <StyledTableCell align="center">
          <Button onClick={handleClick}>Sửa</Button>
          <Button onClick={handleDeleteClick}>Xóa</Button>
        </StyledTableCell>
      </StyledTableRow>
      <ModalCustom
        open={isOpen}
        onClose={handleClose}
        size="large"
        placement="center" 
      >
        <UpdateUser  user={user} setIsOpen={setIsOpen}  />

      </ModalCustom>
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={() => onDelete(user.id)} 
        title="Xác nhận xóa"
        subtitle="Xóa"
        content={`Bạn có chắc chắn muốn xóa người dùng ${user.name}?`}
      />
    </>
  );
};

export default UserTableItem;
