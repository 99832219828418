import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Typography,
  Alert,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';

function OTPVerification({ dataSubmit }) {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(300);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let countdown;
    if (timer > 0 && isTimerActive) {
      countdown = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
      setError('Thời gian đã hết, vui lòng nhấn "Gửi lại OTP" để nhận mã mới!');
    }
    return () => clearInterval(countdown);
  }, [timer, isTimerActive]);

  const handleResend = () => {
    setTimer(300);
    setIsTimerActive(true);
    setError('');

    authService.getOTP(dataSubmit).catch((error) => {
      console.error('Error fetching OTP:', error.response?.data || error);
      setError(`Lỗi khi gửi lại mã OTP: ${error.response?.data?.message || error.message}`);
    });
  };

  const handleConfirm = () => {
    authService
      .register({ ...dataSubmit, otp }) // Gửi OTP và các trường khác đến API
      .then(() => {
        setError('');
        setSuccessDialogOpen(true); // Mở popup khi đăng ký thành công
      })
      .catch((error) => {
        console.error('Error registering account:', error.response?.data || error);
        setError('Số điện thoại đã tồn tại');
      });
  };

  const handleNavigateToLogin = () => {
    setSuccessDialogOpen(false);
    navigate('/login');
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        p: 3,
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: 3,
        textAlign: 'center',
        margin: 'auto',
        marginTop: '20vh',
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Xác thực mã OTP
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Mã xác thực đã được gửi qua gmail: {dataSubmit.email}
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <MuiOtpInput
        value={otp}
        onChange={setOtp}
        length={6}
        sx={{ mb: 3 }}
        disabled={!isTimerActive}
      />

      <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
        <Button onClick={handleResend} sx={{ color: '#d32f2f' }} disabled={isTimerActive}>
          Gửi lại OTP
        </Button>
        <Typography variant="body2" color="text.secondary">
          {isTimerActive
            ? `Mã sẽ hết hạn: ${Math.floor(timer / 60)}:${timer % 60 < 10 ? `0${timer % 60}` : timer % 60}`
            : 'Thời gian đã hết'}
        </Typography>
      </Grid>

      <Button
        variant="contained"
        fullWidth
        onClick={handleConfirm}
        disabled={otp.length !== 6}
        sx={{ bgcolor: otp.length !== 6 ? '#d3d3d3' : '#24417c' }}
      >
        Xác nhận
      </Button>

      <Dialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)}>
        <DialogTitle>Đăng ký thành công</DialogTitle>
        <DialogContent>
          <DialogContentText>Đã đăng ký tài khoản thành công!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNavigateToLogin} color="primary">
            Đến trang đăng nhập
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default OTPVerification;
