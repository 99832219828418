import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PricingCard from '../../components/PricingCard';
//import PricingCard from "../components/PricingCard";

const pricingOptions = [
  {
    title: 'Cơ bản',
    price: '499,000',
    features: [
      '1 ChatBot',
      'Tổng số tin nhắn tối đa là 1500/tháng cho tất cả chatbot',
      'Nhúng vào: Website, Facebook',
      'Hỗ trợ 24/7',
    ],
    color: 'primary',
    hoverColor: 'lightgray',
  },
  {
    title: 'Tiêu chuẩn',
    price: '999,000',
    features: [
      '1 ChatBot',
      'Tổng số tin nhắn tối đa là 4000/tháng cho tất cả chatbot',
      'Nhúng vào: Website, Facebook',
      'Hỗ trợ 24/7',
    ],
    color: 'primary',
    hoverColor: 'lightgray',
  },
  {
    title: 'Nâng cao',
    price: '3,000,000',
    features: [
      '3 ChatBot',
      'Tổng số tin nhắn tối đa là 15000/tháng cho tất cả chatbot',
      'Nhúng vào: Website, Facebook',
      'Hỗ trợ 24/7',
    ],
    color: 'primary',
    hoverColor: 'lightgray',
  },
  {
    title: 'Tùy chỉnh',
    price: 'Liên hệ',
    features: [
      'ChatBot không giới hạn',
      'Không giới hạn tin nhắn /tháng cho tất cả chatbot',
      'Nhúng vào: Website, Facebook',
      'Hỗ trợ 24/7',
    ],
    color: 'primary',
    hoverColor: 'lightgray',
  },
];

const PricingTable = () => {
  return (
    <Box>
      <Typography variant="h4" component="h2" align="center" gutterBottom>
        Bảng giá
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {pricingOptions.map((option, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <PricingCard
              title={option.title}
              price={option.price}
              features={option.features}
              color={option.color}
              hoverColor={option.hoverColor}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PricingTable;
