import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../reducers/slices/modalSlice';

const useModal = (name, component, options) => {
  const { configs, onClose } = options || {};
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal[name] || {});

  const openModal = useCallback(
    (data) => {
      dispatch(
        modalActions.openModal({
          name,
          component,
          isOpen: true,
          configs: configs || modalState.configs || {},
          onClose: onClose || modalState.onClose || (() => {}),
          data,
        })
      );
    },
    [dispatch, name]
  );

  const closeModal = useCallback(
    (data) => {
      dispatch(modalActions.closeModal({ name, data }));
    },
    [dispatch, name]
  );

  return { ...modalState, openModal, closeModal };
};

export { useModal };
