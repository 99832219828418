import { Box, Card, CardContent, Typography } from '@mui/material';
import MainTitle from '../../../components/partials/MainTitle';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import BotSocialConnectList from './BotSocialConnectList';
import { useModal } from '../../../hooks/useModal';
import BotSocialConnectCreateModal from './BotSocialConnectCreateModal';
import BotSocialConnectDetail from './BotSocialConnectDetail';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import userService from '../../../services/userService';

const BotSocialConnect = () => {
  const location = useLocation();
  const { id } = useParams();
  const [page, setPage] = useState([]);
  
  useEffect(() => {
    // Tải dữ liệu khi component được mount
    userService
      .getPageFb()
      .then((res) => {
        setPage(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []); // Chỉ chạy một lần khi component mount

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const success = params.get('success');

    if (success) {
      // Cập nhật thêm thông tin khi có success
      userService
        .getPageFb()
        .then((res) => {
          setPage(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      console.log('Đăng nhập thành công');
    }
  }, [location]);

  const createConnectModal = useModal('create-connect', BotSocialConnectCreateModal, {
    configs: {
      size: 'lg',
    },
  });

  const { currentSocialConnect } = useSelector((state) => state.socialConnect);

  const openConnectModal = () => {
    createConnectModal.openModal({ id, location });
  };

  return (
    <Box>
      <MainTitle title={'Kết nối'} buttonName={'Thêm kết nối'} onClick={openConnectModal} />
      <Card>
        <CardContent>
          {currentSocialConnect ? (
            <BotSocialConnectDetail currentSocialConnect={currentSocialConnect} />
          ) : (
            <>
              <CardContentHeader title={'Danh sách kết nối'} />
              <BotSocialConnectList page={page} />
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default BotSocialConnect;
