import { Box } from '@mui/material';
import Input from './Input';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const SearchInput = ({ placeholder }) => {
  return (
    <Box position={'relative'} display={'flex'} sx={{ width: '100%' }}>
      <SearchOutlinedIcon
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          color: 'gray',
        }}
      />
      <Input placeholder={placeholder} sx={{ pl: '40px', flexGrow: 1 }} />
    </Box>
  );
};

export default SearchInput;
