import httpRequest from '../utils/httpRequest';
const END_POINT = '/affiliations';

const affiliation = {
  getAffiliation: (params) => {
    return httpRequest.get(`${END_POINT}/users/customers`, { params })
      .then((res) => res.data);
  },
};

export default affiliation;
