import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import CardContentHeader from '../../../../components/cards/CardContentHeader';
import packageService from '../../../../services/packageService';
import { useState } from 'react';
import Loading from '../../../../utils/loading';
import { systemPackageEnum } from '../../../../helpers/packageHelper';

const SystemPackagePaymentDetail = ({ handleCloseModal, systemPackage }) => {
  const [loading, setLoading] = useState(false);
  const isCustomPackage = systemPackage?.name === systemPackageEnum.CUSTOM;

  const handleCreatePackage = () => {
    setLoading(true);

    const body = systemPackage?.id
      ? { packageId: systemPackage.id }
      : {
        customPackage: {
          duration: systemPackage.duration,
          maxBot: systemPackage.maxBot,
          maxFile: systemPackage.maxFile,
          maxUrl: systemPackage.maxUrl,
          maxCharacter: systemPackage.maxCharacter,
          maxMessage: systemPackage.maxMessage,
        }
      };


    packageService
      .createPackage(body)
      .then((res) => {
        handleCloseModal()
        
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Box height={'100%'} >
    <Card sx={{ height: '95%' }}>
      <CardContent sx={{ height: '90%', display: 'flex', flexDirection: 'column' }}>
        <CardContentHeader title="Tính tiền" titleSize={18} />
        <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={2}
          >
            <Typography variant="body1">Giá</Typography>
            <Typography variant="body1" fontWeight={500}>
              {isCustomPackage ? "Liên Hệ" : `${systemPackage?.price?.toLocaleString('vi-VN')} VND`}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={2}
          >
            <Typography variant="body1">Khuyến mãi</Typography>
            <Typography variant="body1" fontWeight={500}>
              0%
            </Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={2}
          >
            <Typography variant="body1">Thành tiền</Typography>
            <Typography variant="body1" color={'success.main'} fontWeight={500}>
              {isCustomPackage ? "Liên Hệ" : `${systemPackage?.price?.toLocaleString('vi-VN')} VND`}
            </Typography>
          </Box>
        </Box>
  
        {/* Phần Cần thanh toán và nút Tạo gói */}
        <Box mt="auto" pt={2} borderTop={'1px solid'} borderColor={'grey.300'}>
          <Box
            mb={2}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant="body1" fontWeight={'bold'} fontSize={20}>
              Cần thanh toán
            </Typography>
            <Typography
              variant="body1"
              color={'primary.main'}
              fontWeight={'bold'}
              fontSize={20}
            >
              {isCustomPackage ? "Liên Hệ" : `${systemPackage?.price?.toLocaleString('vi-VN')} VND`}
            </Typography>
          </Box>
          <Box>
            <Button onClick={handleCreatePackage} variant="contained" color="primary" sx={{ width: '100%' }}>
              Tạo gói
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
    {loading ? <Loading /> : ''}
  </Box>
  
  );
};

export default SystemPackagePaymentDetail;
