import { Card, CardContent } from "@mui/material"

const ProfileInformation = () => {
    return (
        <Card>
            <CardContent>
                abc
            </CardContent>
        </Card>
    )
}

export default ProfileInformation