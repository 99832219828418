import { Avatar, Box, Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { botAction } from '../../../reducers/slices/botSlice';

const HeaderBotTabs = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { currentBot, currentBots } = useSelector((state) => state.bot);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenBotList = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseBotList = () => {
    setAnchorElUser(null);
  };

  const handleSelectBot = (bot) => {
    setAnchorElUser(null);
    dispatch(botAction.setCurrentBot(bot));
  };
  return (
    <Box mr={1}>
      <Button
        onClick={handleOpenBotList}
        variant="text"
        sx={{
          fontSize: '0.75rem',
          m: 1,
          px: 1.5,
          py: 1,
          color: 'white',
          bgcolor: theme.palette.primary.dark,
          '&:hover': {
            bgcolor: theme.palette.primary.light,
          },
        }}
      >
        <Avatar sx={{ height: 26, width: 26, mr: 1 }} src={currentBot?.avatar} /> {currentBot?.name}
      </Button>

      <Menu
        sx={{ mt: '45px', '& .MuiList-root': { padding: 1 }, maxHeight: '300px', overflow: 'auto' }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseBotList}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {currentBots?.length &&
          currentBots.map((bot) => (
            <MenuItem
              key={bot.id}
              onClick={() => handleSelectBot(bot)}
              sx={{ width: '240px', borderRadius: 2, p: 1 }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Avatar src={bot.avatar} sx={{ mr: 1, width: 32, height: 32 }} />
                <Typography
                  fontSize={15}
                  fontWeight={500}
                  sx={{ maxWidth: '160px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {bot.name}
                </Typography>
              </Box>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

export default HeaderBotTabs;
