import httpRequest from '../../../utils/httpRequest';
const END_POINT = '/users';

const userByAdmin = {
    getAllUser: async (params) => {
        console.log(params);
        const res = await httpRequest.get(`${END_POINT}`, { params });
        return res.data;
    },
};

export default userByAdmin;
