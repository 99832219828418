import { Avatar, Badge, Box, Tooltip, Typography, useTheme } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const BotFileItem = ({ file, handleDelete }) => {
  const theme = useTheme();
  return (
    <Badge
      sx={{ width: '100%' }}
      badgeContent={
        <Avatar
          sx={{
            cursor: 'pointer',
            '&:hover': { bgcolor: theme.palette.error.main },
            width: 28,
            height: 28,
            p: 1,
            boxSizing: 'border-box',
            mt: 2,
            mr: 2,
            backgroundColor: theme.palette.error.light,
          }}
          onClick={(e) => {
            e.preventDefault();
            handleDelete(file);
          }}
        >
          <CloseOutlinedIcon sx={{ fontSize: 16 }} />
        </Avatar>
      }
    >
      <Box
        width={'100%'}
        height={100}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        border={`2px solid ${theme.palette.background.default}`}
        boxSizing={'border-box'}
        p={2}
        borderRadius={4}
      >
        <FolderOutlinedIcon />
        <Tooltip title={file.name}>
          <Typography
            variant="body2"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {file.name || 'Chưa đặt tên'}
          </Typography>
        </Tooltip>
      </Box>
    </Badge>
  );
};

export default BotFileItem;
