import httpRequest from '../utils/httpRequest';

const uploadService = {
  uploadFile: (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return httpRequest
      .post('/uploads', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => res.data);
  },

  uploadFiles: (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    return httpRequest
      .post('/uploads/multiple', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => res.data);
  },
};

export default uploadService;
