import './App.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppRouter from './routes/AppRouter';
import { getUserByToken } from './reducers/actions/authAction';
import { setHeadersAuthorization } from './utils/httpRequest';
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './utils/loading';
import ModalConfigs from './components/modals/ModalConfigs';

function App() {
  setHeadersAuthorization();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUserByToken());
  }, [dispatch]);

  if (isLoading)
    return (
      <div>
        <Loading loading={isLoading} />
      </div>
    );

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ToastContainer />
        <ModalConfigs />
        <AppRouter />
        {/* <AuthProvider>
        <Routes />
        </AuthProvider> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
