import { Avatar, Box, Tooltip, Typography, useTheme } from '@mui/material';
import { transformTimeAgo, formatDate } from '../../../utils/dateUtil';
import PermPhoneMsgOutlinedIcon from '@mui/icons-material/PermPhoneMsgOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const ChatConversationListItem = ({ chatbox, index }) => {
  const itemRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const chatboxId = queryParams.get('chatboxId') || null;

  const goToChatboxDetail = () => {
    navigate(`?chatboxId=${chatbox?.id}`);
  };

  if (!chatbox) {
    return <></>;
  }
  return (
    <Box
      ref={itemRef}
      px={1}
      py={2}
      borderRadius={2}
      sx={{
        cursor: 'pointer',
        '&:hover': { backgroundColor: theme.palette.action.selected },
      }}
      bgcolor={chatboxId === chatbox?.id ? theme.palette.action.selected : null}
      display={'flex'}
      alignItems={'center'}
      flexDirection={'row'}
      onClick={goToChatboxDetail}
    >
      {chatbox?.avatar ? (
        <Avatar sx={{ width: 46, height: 46, mr: 2 }} src={chatbox?.avatar} />
      ) : (
        <Avatar sx={{ width: 46, height: 46, mr: 2 }}>{index}</Avatar>
      )}
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Typography variant="body1" fontWeight={500} fontSize={16} mb={0.25} className="max-line-1">
          {chatbox?.userInfo?.name || chatbox?.messages[0]?.content}
        </Typography>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Tooltip title={formatDate(chatbox?.messages[0]?.createdAt)}>
            <Typography variant="body2" color={'text.secondary'}>
              {formatDate(chatbox?.messages[0]?.createdAt)}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        {chatbox?.userInfo?.address && (
          <Tooltip placement="top" title={chatbox?.userInfo?.address}>
            <HomeOutlinedIcon sx={{ fontSize: 22, color: theme.palette.info.main, mr: 0.5 }} />
          </Tooltip>
        )}
        {chatbox?.userInfo?.phone && (
          <Tooltip title={chatbox?.userInfo?.phone} placement="top">
            <PermPhoneMsgOutlinedIcon
              sx={{ fontSize: 22, color: theme.palette.success.main, mr: 0.5 }}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default ChatConversationListItem;
