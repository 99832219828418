import React from 'react';
import PricingTable from '../containers/price/PricingTable';

const Price = ({ open, onClose }) => {
  return (
    <div>
      <PricingTable />
    </div>
  );
};

export default Price;
